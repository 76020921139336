import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
const initialFieldValues = {
    clientId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    primaryContact: "",
    email: "",
    phoneNumber: "",
    address: "",
    groupId: "",
    collectionNotes: "",
    password: ""
};
export default function Client() {
    const [clients, setClients] = useState([]);
    const [groups, setGroups] = useState([]);    
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [locations, setLocations] = useState([]);
    const getLocation = async (location) => {
        const response = await axios.get(APIConfig.APIACTIVATEURL+'home/location?input=' + location);
        setLocations(response.data.data)
    }
    const handleLocationChange = (e) => {
        const value = e.target.value
        if (value.trim().length > 2) {
            getLocation(value)
        }
        setValues({
            ...values,
            ['address']: value,
        });
    }
    const selectLocationHandle = (data) => {
        setValues({
            ...values,
            ['address']: data.address,
            ['latitude']: data.latitude,
            ['longitude']: data.longitude
        });
        setLocations('')
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.groupId = values.groupId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.primaryContact = values.primaryContact === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        temp.password = values.password === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("clientId", values.clientId)
            formData.append("groupId", values.groupId)
            formData.append("primaryContact", values.primaryContact)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("address", values.address)
            formData.append("password", values.password)
            formData.append("collectionNotes", values.collectionNotes)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATECLIENT, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATECLIENT, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('clientId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetClients(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.data);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetClients(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.data);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetClients(totalPages)
    }
    const GetFirstPageData = () => {
        GetClients("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
        GetClients(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETECLIENT + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetClients(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetClients(pageNumber);
                    }
                });
        }
    }
    const GetClients = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETCLIENT + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setClients(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const GetGroups = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLBYTYPE+'?GroupTypeName=Client', { ...headerconfig })
            .then((response) => {
                setGroups(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetClients(pageNumber);
        GetGroups();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Clients</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="groupId" className="form-label">Groups</label>
                                            <select name="groupId" value={values.groupId} onChange={handleInputChange} className={"form-select" + applyErrorClass('groupId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                {groups.map(rt =>
                                                    <option value={rt.groupId}>{rt.groupName}</option>
                                                )}
                                            </select>
                                            {errors.groupId === false ? (<div className="validationerror">Please select group </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="primaryContact" className="form-label">Primary Contact<span className="asterik">*</span></label>
                                            <input type="text" value={values.primaryContact} name="primaryContact" onChange={handleInputChange} className={"form-control" + applyErrorClass('primaryContact')} placeholder="Please enter primary contact" />
                                            {errors.primaryContact === false ? (<div className="validationerror">Please enter primary contact </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email<span className="asterik">*</span></label>
                                            <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="phoneNumber" className="form-label">Phone Number<span className="asterik">*</span></label>
                                            <input type="text" value={values.phoneNumber} name="phoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Please enter phone number" />
                                            {errors.phoneNumber === false ? (<div className="validationerror">Please enter phone number </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="password" className="form-label">Password<span className="asterik">*</span></label>
                                            <input type="password" value={values.password} name="password" onChange={handleInputChange} className={"form-control" + applyErrorClass('password')} placeholder="Please enter password" />
                                            {errors.password === false ? (<div className="validationerror">Please enter password </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input type="text" value={values.address} name="address" onChange={handleLocationChange} className={"form-control" + applyErrorClass('address')} placeholder="Please enter address" />
                                            {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                            {locations[0] && <ul className="locationListVideo">
                                            {locations.map(location => <li className="locationListVideoItem" onClick={selectLocationHandle.bind(null, location)}>{location.address}</li>)}
                                        </ul>}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="collectionNotes" className="form-label">Notes</label>
                                            <input type="text" value={values.collectionNotes} name="collectionNotes" onChange={handleInputChange} className={"form-control" + applyErrorClass('collectionNotes')} placeholder="Please enter notes" />
                                            {errors.collectionNotes === false ? (<div className="validationerror">Please enter notes </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                        {btnSubmit === true ? 
                                            (<button type="submit" className="btn btn-primary w-100">Submit</button>) : 
                                            (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                        }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Clients</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Primary Contact </th>
                                                        <th>Email</th>
                                                        <th>Mobile</th>
                                                        <th>Group</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {clients.length > 0 && clients.map((gt, index) =>
                                                        <tr key={gt.clientId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.primaryContact}</td>
                                                            <td className="id">{gt.email}</td>
                                                            <td className="id">{gt.phoneNumber}</td>
                                                            <td className="id">{gt.groupName}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(gt)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, gt.clientId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{clients.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}