import { Routes, Route } from "react-router-dom";
import Login from "./Authentication/Login";
import Logout from "./Authentication/Logout";
import Users from "./SuperAdmin/Users";
import SuperDashboard from "./SuperAdmin/SuperDashboard";
import ChangePassword from "./Pages/ChangePassword";
import GroupType from "./Pages/GroupType";
import Group from "./Pages/Group";
import Item from "./Pages/Item";
import Client from "./SuperAdmin/Client";
import Collector from "./SuperAdmin/Collector";
import Branch from "./SuperAdmin/Branch";
import ClientBranch from "./Clients/ClientBranch";
import CollectorBranch from "./Collector/CollectorBranch";
import ClientUsers from "./Clients/ClientUsers";
import Buyer from "./SuperAdmin/Buyer";
import BuyerBranch from "./Buyer/BuyerBranch";
import CollectorUsers from "./Collector/CollectorUsers";
import BuyerUsers from "./Buyer/BuyerUsers";
import ClientRequests from "./Clients/ClientRequests";
import Requests from "./SuperAdmin/Requests";
import RequestDetails from "./Pages/RequestDetails";
import RequestView from "./Pages/RequestView";
import CollectorRequests from "./Collector/CollectorRequests";
import CollectorRequestDetails from "./Collector/CollectorRequestDetails";
import CollectorUserRequests from "./Collector/CollectorUserRequests";
import CollectorUserCollect from "./Collector/CollectorUserCollect";
import CollectorUserRequestDetails from "./Collector/CollectorUserRequestDetails";
import ClientDashboard from "./Clients/ClientDashboard";
import Profile from "./Pages/Profile";
import ClientUserRequests from "./Clients/ClientUserRequests";
import CollectorDashboard from "./Collector/CollectorDashboard";
import CollectorRequestView from "./Collector/CollectorRequestView";
import BuyerDashboard from "./Buyer/BuyerDashboard";
import InfinityReport from "./Reports/InfinityReport";
import ClientReport from "./Reports/ClientReport";
import TrackStatus from "./Pages/TrackStatus";
import Register from "./Authentication/Register";
import CollectorRegister from "./Authentication/CollectorRegistration";
import Lead from "./Pages/Lead";
import TestPage from "./Reports/TestPage";
import RecycleItem from "./Pages/RecycleItem";
import Logistic from "./Pages/Logistic";
import ClientRequestView from "./Clients/ClientRequestView";
import ClientPickupRequests from "./PickupRequests/ClientPickupRequests";
import ClientPickupRequest from "./PickupRequests/ClientPickupRequest";
import PickUpRequestView from "./PickupRequests/PickupRequestDetails";
import PickupTracking from "./PickupRequests/PickupTracking";
import Invoices from "./SuperAdmin/Invoices";
import InvoiceDetails from "./SuperAdmin/InvoiceDetails";
import ViewInvoice from "./SuperAdmin/ViewInvoice";
function App() {
  return (
      <Routes>
        <Route path="/" element={ <Login/> } />
        <Route path="/login" element={ <Login/> } />
        <Route path="/register" element={ <Register/> } />
        <Route path="/collector-register" element={ <CollectorRegister/> } />
        <Route path="/logout" element={ <Logout/> } />
        <Route path="/users" element={ <Users/> } />
        <Route path="/changepassword" element={ <ChangePassword/> } />
        <Route path="/profile" element={ <Profile/> } />
        <Route path="/test" element={ <TestPage/> } />
        
        <Route path="/superadmin/dashboard" element={ <SuperDashboard/> } />
        <Route path="/superadmin/clients" element={ <Client/> } />
        <Route path="/superadmin/collectors" element={ <Collector/> } />
        <Route path="/superadmin/buyers" element={ <Buyer/> } />
        <Route path="/superadmin/branches" element={ <Branch/> } />
        <Route path="/superadmin/users" element={ <Users/> } />
        <Route path="/superadmin/leads" element={ <Lead/> } />

        <Route path="/requests" element={ <Requests/> } />
        <Route path="/requestdetails/:requestId" element={<RequestDetails />} />
        <Route path="/trackstatus/:requestId" element={<TrackStatus />} />
        <Route path="/requestview/:requestId" element={<RequestView />} />

        <Route path="/clientdashboard" element={ <ClientDashboard/> } />
        <Route path="/clientbranches" element={ <ClientBranch/> } />
        <Route path="/clientusers" element={ <ClientUsers/> } />
        <Route path="/clientrequests" element={ <ClientRequests/> } />
        <Route path="/clientrequestview/:requestId" element={ <ClientRequestView/> } />
        <Route path="/clientuserrequests" element={ <ClientUserRequests/> } />
        

        <Route path="/collectordashboard" element={ <CollectorDashboard/> } />
        <Route path="/collectorbranches" element={ <CollectorBranch/> } />
        <Route path="/collectorusers" element={ <CollectorUsers/> } />
        <Route path="/collectorrequests" element={ <CollectorRequests/> } />
        <Route path="/collectorrequestdetails/:requestId" element={<CollectorRequestDetails />} />
        <Route path="/collectorrequestview/:requestId" element={<CollectorRequestView />} />
        <Route path="/collectoruserrequests" element={ <CollectorUserRequests/> } />
        <Route path="/collectorusercollect/:requestId" element={<CollectorUserCollect />} />
        <Route path="/collectoruserrequestdetails/:requestId" element={<CollectorUserRequestDetails />} />

        <Route path="/buyerdashboard" element={ <BuyerDashboard/> } />
        <Route path="/buyerbranches" element={ <BuyerBranch/> } />
        <Route path="/buyerusers" element={ <BuyerUsers/> } />

        <Route path="/grouptypes" element={ <GroupType/> } />
        <Route path="/groups" element={ <Group/> } />
        <Route path="/items" element={ <Item/> } />
        <Route path="/logisitics" element={ <Logistic/> } />
        <Route path="/recycleitems" element={ <RecycleItem/> } />

        <Route path="/admin-reports" element={ <InfinityReport/> } />
        <Route path="/client-reports" element={ <ClientReport/> } />
        <Route path="/invoices" element={ <Invoices/> } />
        <Route path="/invoice/:pickupRequestId?" element={ <InvoiceDetails/> } />
        <Route path="/viewinvoice/:pickupRequestId?" element={ <ViewInvoice/> } />

        <Route path="/pickuprequests" element={ <ClientPickupRequests/> } />
        <Route path="/newpickuprequest" element={ <ClientPickupRequest/> } />
        <Route path="/pickuprequestview/:pickupRequestId" element={ <PickUpRequestView/> } />
        <Route path="/pickuprequesttracking/:pickupRequestId" element={ <PickupTracking/> } />
      </Routes>
  );
}

export default App;
