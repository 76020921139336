import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from "moment";
import Flatpickr from 'react-flatpickr'
const initialFieldValues = {
    leadId: "00000000-0000-0000-0000-000000000000",
    name: "",
    phoneNumber: "",
    companyName: "",
    email: "",
    alternatePhoneNumber: "",
    status: "0",
    city: "",
    address: "",
    followUpDate: "",
    notes: "",
};
export default function Lead() {
    const [leads, setLeads] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [btnSubmit, setBtnSubmit] = useState(true);

    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.companyName = values.companyName === "" ? false : true;
        temp.name = values.name === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        temp.status = values.status === "0" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("leadId", values.leadId)
            formData.append("companyName", values.companyName)
            formData.append("name", values.name)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("alternatePhoneNumber", values.alternatePhoneNumber === null ? "" : values.alternatePhoneNumber)
            formData.append("city", values.city === null ? "" : values.city)
            formData.append("address", values.address === null ? "" : values.address)
            formData.append("notes", values.notes === null ? "" : values.notes)
            formData.append("status", values.status)
            formData.append("followUpDate", moment(values.followUpDate).format('YYYY-MM-DD'))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATELEAD, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATELEAD, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('leadId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetLeads(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetLeads(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetLeads(totalPages)
    }
    const GetFirstPageData = () => {
        GetLeads("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetLeads(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETELEAD + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetLeads(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetLeads(pageNumber);
                    }
                });
        }
    }
    const GetLeads = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETLEADS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setLeads(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetLeads(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Leads</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="companyName" className="form-label">Company Name<span className="asterik">*</span></label>
                                            <input type="text" value={values.companyName} name="companyName" onChange={handleInputChange} className={"form-control" + applyErrorClass('companyName')} placeholder="Please enter company name" />
                                            {errors.companyName === false ? (<div className="validationerror">Please enter company name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="name" className="form-label">Name<span className="asterik">*</span></label>
                                            <input type="text" value={values.name} name="name" onChange={handleInputChange} className={"form-control" + applyErrorClass('name')} placeholder="Please enter name" />
                                            {errors.name === false ? (<div className="validationerror">Please enter name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" value={values.email} name="email" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="phoneNumber" className="form-label">Phone Number<span className="asterik">*</span></label>
                                            <input type="text" value={values.phoneNumber} name="phoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Please enter phoneNumber" />
                                            {errors.phoneNumber === false ? (<div className="validationerror">Please enter phoneNumber </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="alternatePhoneNumber" className="form-label">Alternate Phone Number</label>
                                            <input type="text" value={values.alternatePhoneNumber} name="alternatePhoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('alternatePhoneNumber')} placeholder="Alternate Phone Number" />
                                            {errors.alternatePhoneNumber === false ? (<div className="validationerror">Please enter alternatePhoneNumber </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">City</label>
                                            <input type="text" value={values.city} name="city" onChange={handleInputChange} className={"form-control" + applyErrorClass('city')} placeholder="Please enter city" />
                                            {errors.city === false ? (<div className="validationerror">Please enter city </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="city" className="form-label">Status</label>
                                            <select value={values.status} name="status" onChange={handleInputChange} className={"form-select" + applyErrorClass('status')}>
                                                <option value="0">Please select</option>
                                                <option value="NEW">NEW</option>
                                                <option value="FOLLOWUP">FOLLOW UP</option>
                                                <option value="CALL ME BACK">CALL ME BACK</option>
                                                <option value="NOT LIFTING">NOT LIFTING</option>
                                                <option value="NOT WORKING">NOT WORKING</option>
                                                <option value="DUPLICATE">DUPLICATE</option>
                                                <option value="NOT INTERESTED">NOT INTERESTED</option>
                                            </select>
                                            {errors.status === false ? (<div className="validationerror">Please select status </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="JoiningdatInput" className="form-label">Joining Date</label>
                                            <div className="input-group">
                                                <Flatpickr
                                                    value={moment(values.followUpDate).format('YYYY-MM-DD')}
                                                    onChange={(selectedDates, dateStr) => {
                                                        setValues({
                                                            ...values,
                                                            ['followUpDate']: selectedDates[0],
                                                        });
                                                    }}
                                                    name="followUpDate"
                                                    className={"form-control" + applyErrorClass('followUpDate')}
                                                    placeholder="Follow Up"
                                                    minDate="today"
                                                    data-date-format="Y-m-d"
                                                />
                                                <div className="input-group-text bg-primary border-primary text-white">
                                                    <i className="ri-calendar-2-line"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input type="text" value={values.address} name="address" onChange={handleInputChange} className={"form-control" + applyErrorClass('address')} placeholder="Please enter address" />
                                            {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Please enter notes" />
                                            {errors.notes === false ? (<div className="validationerror">Please enter notes </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {btnSubmit === true ?
                                                (<button type="submit" className="btn btn-primary w-100">Submit</button>) :
                                                (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Leads</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Company Name</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone</th>
                                                        <th>Alternate</th>
                                                        <th>FollowUp</th>
                                                        <th>Last Updated</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {leads.length > 0 && leads.map((gt, index) =>
                                                        <tr key={gt.leadId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.companyName}</td>
                                                            <td className="id">{gt.name}</td>
                                                            <td className="id">{gt.email}</td>
                                                            <td className="id">{gt.phoneNumber}</td>
                                                            <td className="id">{gt.alternatePhoneNumber}</td>
                                                            <td className="id">{moment(moment.utc(gt.followUpDate).toDate()).format('YYYY-MM-DD')}</td>
                                                            <td className="id">{moment(moment.utc(gt.updatedDate).toDate()).format('YYYY-MM-DD HH:mm a')}</td>
                                                            {gt.status === "NEW" ?
                                                                <td className="id"><span className="badge text-bg-primary">{gt.status}</span></td>
                                                                : gt.status === "FOLLOW UP" ?
                                                                    <td className="id"><span className="badge text-bg-success">{gt.status}</span></td>
                                                                    : gt.status === "CALL ME BACK" ?
                                                                        <td className="id"><span className="badge text-bg-warning">{gt.status}</span></td>
                                                                        :
                                                                        <td className="id"><span className="badge text-bg-danger">{gt.status}</span></td>
                                                            }
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(gt)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, gt.leadId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{leads.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}