import Header from "../Common/Layouts/Header";
import SideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
const initialChangePasswordValues = {
    id: localStorage.getItem('userId'),
    email: "",
    primaryContact: "",
    phoneNumber: "",
    roleName:"",
    address:""
};
export default function Profile() {
    const [values, setValues] = useState(initialChangePasswordValues);
    const [errors, setErrors] = useState({});
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const validate = () => {
        let temp = {};
        temp.primaryContact = values.primaryContact === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData()
            formData.append("id", values.id)
            formData.append("primaryContact", values.primaryContact)
            formData.append("email", values.email)
            formData.append("phoneNumber", values.phoneNumber)
            formData.append("address", values.address)
            formData.append("roleName", values.roleName)
            addOrEdit(formData)
        }
    };
    const addOrEdit = (formData) => {
        applicationAPI().update(formData)
            .then(res => {
                if (res.data.statusCode === 200) {
                    handleSuccess(res.data.message);
                }
                else {
                    handleError(res.data.message);
                }
            })
    }
    const applicationAPI = () => {
        return {
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEUSERPROFILE, updateRecord, { ...headerconfig })
        };
    };
    const clearForm = () => {
        setValues(initialChangePasswordValues);
    };
    const GetProfile = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETUSERBYID + "?UserId=" + localStorage.getItem('userId'), { ...headerconfig })
            .then((response) => {
                setValues(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetProfile();
    }, [])
    return (
        <div id="layout-wrapper">
            <Header></Header>
            <SideBar></SideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Profile</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="primaryContact" className="form-label">Name</label>
                                            <input type="text" value={values.primaryContact} name="primaryContact" onChange={handleInputChange} className={"form-control" + applyErrorClass('primaryContact')} placeholder="Name" />
                                            {errors.primaryContact === false ? (<div className="validationerror">Please enter name </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input type="text" value={values.email} name="newPassword" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Email" />
                                            {errors.email === false ? (<div className="validationerror">Please enter email </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                            <input type="text" value={values.phoneNumber} name="phoneNumber" onChange={handleInputChange} className={"form-control" + applyErrorClass('phoneNumber')} placeholder="Phone Number" />
                                            {errors.phoneNumber === false ? (<div className="validationerror">Please enter phone number </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input type="text" value={values.address} name="address" onChange={handleInputChange} className={"form-control" + applyErrorClass('address')} placeholder="Address" />
                                            {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            <button type="submit" className="btn btn-primary">Update Profile</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}