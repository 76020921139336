import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
export default function PickupTracking() {
    const navigate = useNavigate();
    const params = useParams();
    const [request, setRequest] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPICKUPREQUESTBYID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    useEffect(() => {
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Track Request Status</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header">
                                <div className="d-sm-flex align-items-center">
                                    <h5 className="card-title flex-grow-1 mb-0">Request Status</h5>
                                    <div className="flex-shrink-0 mt-2 mt-sm-0">
                                        {localStorage.getItem('roleName') === "Client" ?
                                            <Link to={"/clientrequests"} className="btn btn-info btn-sm mt-2 mt-sm-0">Back to list</Link>
                                            :
                                            localStorage.getItem('roleName') === "Collector" ?
                                                <Link to={"/collectorrequests"} className="btn btn-info btn-sm mt-2 mt-sm-0">Back to list</Link>
                                                :
                                                localStorage.getItem('roleName') === "CollectorUser" ?
                                                    <Link to={"/collectoruserrequests"} className="btn btn-info btn-sm mt-2 mt-sm-0">Back to list</Link>
                                                    :
                                                    <Link to={"/requests"} className="btn btn-info btn-sm mt-2 mt-sm-0">Back to list</Link>}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="profile-timeline">
                                    {request.status === "NEW" ?
                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingOne">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-success rounded-circle">
                                                                    <i className="ri-shopping-bag-line" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                        <h6 className="mb-1">An request has been raised.</h6>
                                                        <p className="text-muted">{moment(request.requestedDate).format('DD MMM YYYY hh:mm a')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingTwo">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i className="mdi mdi-account-outline" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Collector not assigned</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingThree">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i className="ri-truck-line" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Driver not assigned</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingFour">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i className="ri-takeaway-fill" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Pickup pending</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingFive">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFive" aria-expanded="false">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i className="ri-takeaway-fill" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Delivery pending</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingSix">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSix" aria-expanded="false">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i className="ri-recycle-fill" />
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Recycle pending</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="accordion-item border-0">
                                                <div className="accordion-header" id="headingSeven">
                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                        <div className="d-flex align-items-center">
                                                            <div className="flex-shrink-0 avatar-xs">
                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                    <i class="ri-thumb-up-line"></i>
                                                                </div>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3">
                                                                <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                        : request.status === "COLLECTOR ASSIGNED" ?
                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingOne">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                        <i className="ri-shopping-bag-line" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                            <h6 className="mb-1">An request has been raised.</h6>
                                                            <p className="text-muted">{moment(moment.utc(request.requestedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingTwo">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                        <i className="mdi mdi-account-outline" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingThree">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                        <i className="ri-truck-line" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-12 mb-1 fst-italic">Driver not assigned</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                    <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingFour">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                        <i className="ri-takeaway-fill" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-12 mb-1 fst-italic">Pickup pending</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingFive">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFive" aria-expanded="false">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                        <i className="ri-takeaway-fill" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-12 mb-1 fst-italic">Delivery pending</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingSix">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSix" aria-expanded="false">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                        <i className="ri-recycle-fill" />
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-12 mb-1 fst-italic">Recycle pending</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="accordion-item border-0">
                                                    <div className="accordion-header" id="headingSeven">
                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                            <div className="d-flex align-items-center">
                                                                <div className="flex-shrink-0 avatar-xs">
                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                        <i class="ri-thumb-up-line"></i>
                                                                    </div>
                                                                </div>
                                                                <div className="flex-grow-1 ms-3">
                                                                    <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            : request.status === "DRIVER ASSGINED" ?
                                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingOne">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                            <i className="ri-shopping-bag-line" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                <h6 className="mb-1">An request has been raised.</h6>
                                                                <p className="text-muted">{moment(request.requestedDate).format('DD MMM YYYY hh:mm a')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingTwo">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                            <i className="mdi mdi-account-outline" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                                    <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingThree">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                            <i className="ri-truck-line" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-15 mb-0 fw-semibold">Driver Assigned</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                <p className="text-muted">{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingFour">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-light text-success rounded-circle">
                                                                            <i className="ri-takeaway-fill" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-12 mb-1 fst-italic">Pickup pending</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingFive">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFive" aria-expanded="false">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-light text-success rounded-circle">
                                                                            <i className="ri-takeaway-fill" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-12 mb-1 fst-italic">Delivery pending</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingSix">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSix" aria-expanded="false">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-light text-success rounded-circle">
                                                                            <i className="ri-recycle-fill" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-12 mb-1 fst-italic">Recycle pending</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-item border-0">
                                                        <div className="accordion-header" id="headingSeven">
                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                                <div className="d-flex align-items-center">
                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                        <div className="avatar-title bg-light text-success rounded-circle">
                                                                            <i class="ri-thumb-up-line"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div className="flex-grow-1 ms-3">
                                                                        <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                : request.status === "COLLECTED" ?
                                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingOne">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-success rounded-circle">
                                                                                <i className="ri-shopping-bag-line" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                                    <h6 className="mb-1">An request has been raised.</h6>
                                                                    <p className="text-muted">{moment(moment.utc(request.requestedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingTwo">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-success rounded-circle">
                                                                                <i class="ri-map-pin-user-fill"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                                <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                                        <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingThree">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-success rounded-circle">
                                                                                <i className="ri-truck-line" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">Driver Assigned</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                                    <p className="text-muted">{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingFour">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-success rounded-circle">
                                                                                <i className="ri-takeaway-fill" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-15 mb-0 fw-semibold">Collected</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                            <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                                    <p className="text-muted">{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingFive">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFive" aria-expanded="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                <i className="ri-takeaway-fill" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-12 mb-1 fst-italic">Delivery pending</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingSix">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSix" aria-expanded="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                <i className="ri-recycle-fill" />
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-12 mb-1 fst-italic">Recycle pending</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="accordion-item border-0">
                                                            <div className="accordion-header" id="headingSeven">
                                                                <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                                    <div className="d-flex align-items-center">
                                                                        <div className="flex-shrink-0 avatar-xs">
                                                                            <div className="avatar-title bg-light text-success rounded-circle">
                                                                                <i class="ri-thumb-up-line"></i>
                                                                            </div>
                                                                        </div>
                                                                        <div className="flex-grow-1 ms-3">
                                                                            <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : request.status === "DELIVERED" ?
                                                        <div className="accordion accordion-flush" id="accordionFlushExample">
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingOne">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-shopping-bag-line" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                                        <h6 className="mb-1">An request has been raised.</h6>
                                                                        <p className="text-muted">{moment(moment.utc(request.requestedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingTwo">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-success rounded-circle">
                                                                                    <i className="mdi mdi-account-outline" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                    <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingThree">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-truck-line" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">Driver Assigned</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                                        <p className="text-muted">{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingFour">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-takeaway-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">Collected</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                                        <p className="text-muted">{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingFour">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-success rounded-circle">
                                                                                    <i className="ri-takeaway-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-15 mb-0 fw-semibold">Delivered to plant</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                                <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                    <div className="accordion-body ms-2 ps-5 pt-0">
                                                                        <p className="text-muted">{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingSix">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSix" aria-expanded="false">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                                    <i className="ri-recycle-fill" />
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-12 mb-1 fst-italic">Recycle pending</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="accordion-item border-0">
                                                                <div className="accordion-header" id="headingSeven">
                                                                    <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="flex-shrink-0 avatar-xs">
                                                                                <div className="avatar-title bg-light text-success rounded-circle">
                                                                                    <i class="ri-thumb-up-line"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div className="flex-grow-1 ms-3">
                                                                                <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : request.status === "RECYCLED" ?
                                                            <div className="accordion accordion-flush" id="accordionFlushExample">
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingOne">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="ri-shopping-bag-line" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <h6 className="mb-1">An request has been raised.</h6>
                                                                            <p className="text-muted">{moment(moment.utc(request.requestedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingTwo">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="mdi mdi-account-outline" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                        <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingThree">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="ri-truck-line" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">Driver Assigned</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <p className="text-muted">{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingFour">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="ri-takeaway-fill" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">Collected</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <p className="text-muted">{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingFour">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="ri-takeaway-fill" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">Delivered to plant</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <p className="text-muted">{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingFour">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-success rounded-circle">
                                                                                        <i className="ri-takeaway-fill" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-15 mb-0 fw-semibold">RECYCLED</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                    <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                        <div className="accordion-body ms-2 ps-5 pt-0">
                                                                            <p className="text-muted">{moment(moment.utc(request.deliverDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="accordion-item border-0">
                                                                    <div className="accordion-header" id="headingSeven">
                                                                        <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#headingSeven" aria-expanded="false">
                                                                            <div className="d-flex align-items-center">
                                                                                <div className="flex-shrink-0 avatar-xs">
                                                                                    <div className="avatar-title bg-light text-success rounded-circle">
                                                                                        <i class="ri-thumb-up-line"></i>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3">
                                                                                    <h6 className="fs-12 mb-1 fst-italic">Item not ready to sale</h6>
                                                                                </div>
                                                                            </div>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : request.status === "SOLD" ?
                                                                <div className="accordion accordion-flush" id="accordionFlushExample">
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingOne">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-shopping-bag-line" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">Request Received</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <h6 className="mb-1">An request has been raised.</h6>
                                                                                <p className="text-muted">{moment(moment.utc(request.requestedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingTwo">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="mdi mdi-account-outline" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">Collector Assigned</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                                <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                    <p className="text-muted">{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingThree">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-truck-line" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">Driver Assigned</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingFour">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-takeaway-fill" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">Collected</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingFour">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-takeaway-fill" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">Delivered to plant</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingFour">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-recycle-fill" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">RECYCLED</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.recycledDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item border-0">
                                                                        <div className="accordion-header" id="headingFour">
                                                                            <a className="accordion-button p-2 shadow-none" data-bs-toggle="collapse" href="#collapseFour" aria-expanded="false">
                                                                                <div className="d-flex align-items-center">
                                                                                    <div className="flex-shrink-0 avatar-xs">
                                                                                        <div className="avatar-title bg-success rounded-circle">
                                                                                            <i className="ri-thumb-up-line" />
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="flex-grow-1 ms-3">
                                                                                        <h6 className="fs-15 mb-0 fw-semibold">SOLD</h6>
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </div>
                                                                        <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                                            <div className="accordion-body ms-2 ps-5 pt-0">
                                                                                <p className="text-muted">{moment(moment.utc(request.saleDate).toDate()).format('DD MMM YYYY hh:mm a')}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                : "hello"

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}