import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
export default function CollectorRequestView() {
    const params = useParams();
    const [request, setRequest] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTCOLLECTORVIEWBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    useEffect(() => {
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Branch
                                            </th>
                                            <td colSpan={5}>
                                                {request.branchName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Primary Contact
                                            </th>
                                            <td colSpan={5}>
                                                {request.branchPrimaryContact}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Phone Number
                                            </th>
                                            <td colSpan={5}>
                                                {request.branchPhone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Address
                                            </th>
                                            <td colSpan={5}>
                                                {request.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Collector
                                            </th>
                                            <td>
                                                {request.collectorName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">Driver</th>
                                            <td>{request.driverName}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td>
                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                    <Link to={"/collectorrequests"} className="btn btn-danger">Back to list</Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}