import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
const initialFieldValues = {
    requestRecycleId: "00000000-0000-0000-0000-000000000000",
    pickupRequestId: "00000000-0000-0000-0000-000000000000",
    recycledItemId: "00000000-0000-0000-0000-000000000000",
    quantity: "",
    weight: ""
};
export default function PickUpRequestView() {
    const params = useParams();
    const navigate = useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [request, setRequest] = useState([]);
    const [invoice, setInvoice] = useState(false);
    const [items, setItems] = useState([]);
    const [recycleItems, setRecycleItems] = useState([]);
    const [recycledItems, setRecycledItems] = useState([]);
    const [requestHistory, setRequestHistory] = useState([]);
    const [logisticId, setLogisticId] = useState("00000000-0000-0000-0000-000000000000");
    const [collectors, setCollectors] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [sealNumber, setSealNumber] = useState('');
    const [truckReferenceNumber, setTruckReferenceNumber] = useState('');
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.recycledItemId = values.recycledItemId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.quantity = values.quantity === "" ? false : true;
        temp.weight = values.weight === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("requestRecycleId", values.requestRecycleId)
            formData.append("pickupRequestId", params.pickupRequestId)
            formData.append("recycledItemId", values.recycledItemId)
            formData.append("weight", values.weight)
            formData.append("quantity", values.quantity)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEREQUESTRECYCLE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEREQUESTRECYCLE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('requestRecycleId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetRecycledItems();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetRecycledItems();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETEREQUESTRECYCLE + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetRecycledItems();
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetRecycledItems();
                    }
                });
        }
    }
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetRecycledItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTRECYCLEBYREQUESTID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRecycledItems(response.data.data.data);
            });
    };
    const GetRecycleItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLRECYCLEITEM, { ...headerconfig })
            .then((response) => {
                setRecycleItems(response.data.data);
            });
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPICKUPREQUESTBYID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
                if (response.data.data != null) {
                    setItems(response.data.data.pickupRequestItems);
                    setRequestHistory(response.data.data.requestHistory);
                    setRecycledItems(response.data.data.pickupRecycleItems);
                }
            });
    };
    const AssignCollector = (e, requestId, logisticId) => {
        e.preventDefault();
        if (logisticId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select collector");
        }
        else if (window.confirm('Are you sure to assign this collector?')) {
            const formData = {
                "pickupRequestId": requestId,
                "logisticId": logisticId,
                "sealNumber": sealNumber,
                "truckReferenceNumber": truckReferenceNumber
            }
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNLOGISTICPICKUPREQUEST, formData, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        setBtnSubmit(false)
                        navigate('/pickuprequests')
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const RequestStatus = (e, requestId, Status) => {
        e.preventDefault();
        if (window.confirm('Are you sure to update the status?')) {
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.PICKUPREQUESTSTATUS + "?PickupRequestId=" + requestId + "&Status=" + Status, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        setBtnSubmit(false)
                        navigate('/pickuprequests')
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setLogisticId(value);
    };
    const GetLogistics = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLLOGISTIC, { ...headerconfig })
            .then((response) => {
                setCollectors(response.data.data);
            });
    };
    const GetInvoice = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETINVOICEBYREQUEST + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                console.log(response.data)
                if (response.data.data != "No Records") {
                    setInvoice(true);
                }
                else {
                    setInvoice(false);
                }
            });
    };
    useEffect(() => {
        GetRequest();
        GetLogistics();
        GetRecycleItems();
        GetInvoice();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm">
                                                <h4 className="mb-sm-0">Request Details</h4>
                                            </div>
                                            <div className="col-sm">
                                                <div className="hstack gap-2 justify-content-end">
                                                    {localStorage.getItem('roleName') === 'Infinity' && invoice === false?
                                                        <Link to={"/invoice/" + request.pickupRequestId} className="btn btn-primary add-btn"> Create Invoice</Link>
                                                        : localStorage.getItem('roleName') === 'Infinity' && invoice === true ?
                                                            <Link to={"/viewinvoice/" + request.pickupRequestId} className="btn btn-primary add-btn"> View Invoice</Link>
                                                            : localStorage.getItem('roleName') === 'Client' && invoice === true ?
                                                                <Link to={"/viewinvoice/" + request.pickupRequestId} className="btn btn-warning add-btn"> View Invoice</Link>
                                                                : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body p-0">
                                        <div className="row text-nowrap p-3">
                                            <div className="col-lg-6">
                                                <div className="mb-3">
                                                    <b className="mr-2">Client Details</b> :
                                                    <ul className="list-unstyled">
                                                        <li>Client : {request.clientName}</li>
                                                        <li>Phone : {request.userPhoneNumber}</li>
                                                        <li>Name : {request.userName}</li>
                                                        <li>Email : {request.userEmail}</li>
                                                        <li>Address : {request.address}</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-4">
                                                    <div className="mb-1">
                                                        <b className="mr-2">Status</b> : <span className="badge bg-success">{request.status}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            {request.status !== "NEW" ?
                                                <div className="col-lg-6">
                                                    <div className="mb-3">
                                                        <b className="mr-2">Collector Details</b> :
                                                        <ul className="list-unstyled">
                                                            <li>Collector : {request.logisticName}</li>
                                                            <li>Phone : {request.logisticPhoneNumber}</li>
                                                            <li>Email : {request.logisticEmail}</li>
                                                            <li>Truck Reference Number : {request.truckReferenceNumber}</li>
                                                            <li>Seal Number : {request.sealNumber}</li>
                                                        </ul>
                                                    </div>
                                                </div> : ""}
                                        </div>
                                        {request.status === "NEW" ?
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label>Truck Company</label>
                                                        <select name="logisticId" value={logisticId} onChange={handleCollectorChange} className="form-select">
                                                            <option value="00000000-0000-0000-0000-000000000000">Please Select Collector</option>
                                                            {collectors.map(rt =>
                                                                <option value={rt.logisticId}>{rt.companyName}</option>
                                                            )}
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label>Truck Reference Number</label>
                                                        <input type="text" value={truckReferenceNumber} name="truckReferenceNumber" onChange={(e) => setTruckReferenceNumber(e.target.value)} className={"form-control" + applyErrorClass('truckReferenceNumber')} placeholder="Truck ReferenceNumber" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="mb-3">
                                                        <label>Seal Number</label>
                                                        <input type="text" value={sealNumber} name="sealNumber" onChange={(e) => setSealNumber(e.target.value)} className={"form-control" + applyErrorClass('sealNumber')} placeholder="Seal Number" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                        <button onClick={e => AssignCollector(e, request.pickupRequestId, logisticId)} className="btn btn-success add-btn"> Assign Collector</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        {request.status === "COLLECTOR ASSIGNED" ?
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-2">
                                                    <div className="mb-3">
                                                        Is Items Collected? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "COLLECTED")} className="btn btn-success add-btn"> YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""
                                        }
                                        {localStorage.getItem('roleName') === "Infinity" && request.status === "COLLECTED" ?
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-2">
                                                    <div className="mb-3">
                                                        Is Items Reached Plant? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "DELIVERED")} className="btn btn-success add-btn"> YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                        {localStorage.getItem('roleName') === "Infinity" && request.status === "DELIVERED" ?
                                            <div className="row text-nowrap p-3">
                                                <div className="col-lg-2">
                                                    <div className="mb-3">
                                                        Is Items Recycled? : <button onClick={e => RequestStatus(e, request.pickupRequestId, "RECYCLED")} className="btn btn-success add-btn"> YES</button>
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-6">
                                <div className="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Pickup Items</h4>
                                    </div>
                                    <div className="card-body p-0">
                                        <table className="table align-middle" id="customerTable">
                                            <thead>
                                                <tr>
                                                    <th>Item Name</th>
                                                    <th>Quantity</th>
                                                    <th>Weight</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {items.length > 0 && items.map(i =>
                                                    <tr>
                                                        <td>{i.itemName}</td>
                                                        <td>{i.quantity}</td>
                                                        <td>{i.weight}</td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6">
                                <div className="card">
                                    <div class="card-header align-items-center d-flex">
                                        <h4 class="card-title mb-0 flex-grow-1">Recycled Items</h4>
                                    </div>
                                    <div className="card-body p-0">
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <table className="table align-middle" id="customerTable">
                                                <thead>
                                                    <tr>
                                                        <th>Item Name</th>
                                                        <th>Quantity</th>
                                                        <th>Weight</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {recycledItems.length > 0 && recycledItems.map(i =>
                                                        <tr>
                                                            <td>{i.itemName}</td>
                                                            <td>{i.quantity}</td>
                                                            <td>{i.weight}</td>
                                                            <td>
                                                                {localStorage.getItem('roleName') === "Infinity" && request.status === "RECYCLED" ?
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                            <Link className="remove-item-btn" onClick={e => onDelete(e, i.requestRecycleId)}>
                                                                                <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                            </Link>
                                                                        </li>
                                                                    </ul> : ""}
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                                {localStorage.getItem('roleName') === "Infinity" && request.status === "RECYCLED" ?
                                                    <tfoot>
                                                        <tr>
                                                            <td>
                                                                <select name="recycledItemId" value={values.recycledItemId} onChange={handleInputChange} className={"form-select" + applyErrorClass('recycledItemId')}>
                                                                    <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                                    {recycleItems.map(rt =>
                                                                        <option value={rt.recycledItemId}>{rt.itemName}</option>
                                                                    )}
                                                                </select>
                                                                {errors.recycledItemId === false ? (<div className="validationerror">Please select item </div>) : ('')}
                                                            </td>
                                                            <td>
                                                                <input type="text" value={values.quantity} className="form-control" onChange={handleInputChange} name="quantity"></input>
                                                            </td>
                                                            <td>
                                                                <input type="text" value={values.weight} className="form-control" onChange={handleInputChange} name="weight" placeholder="Kgs, lbs"></input>
                                                            </td>
                                                            <td>
                                                                {btnSubmit === true ?
                                                                    (<button type="submit" className="btn btn-primary w-100">Add</button>) :
                                                                    (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tfoot>
                                                    : ""}
                                            </table>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body p-0">
                                        <div class="container mt-1" style={{ paddingBottom: "100px" }}>
                                            <h2 class="text-center">Request Tracking</h2>
                                            <ul class="timeline">
                                                {requestHistory.length > 0 && requestHistory.map((rh, index) =>
                                                    <li class="timeline-item">
                                                        <div class="timeline-panel">
                                                            <div class="timeline-heading">
                                                                <h5 class="timeline-title">{rh.status}</h5>
                                                                <p><small class="text-muted">{moment(rh.updatedDate).format('DD MMM YYYY hh:mm a')}</small></p>
                                                            </div>
                                                            <div class="timeline-body">
                                                                {rh.status === "NEW" ?
                                                                    <p className="mb-1 mt-2">New pickup request received</p>
                                                                    :
                                                                    rh.status === "COLLECTOR ASSIGNED" ?
                                                                        <p className="mb-1 mt-2">Collector assigned to pickup items</p>
                                                                        :
                                                                        rh.status === "COLLECTED" ?
                                                                            <h6 className="mb-1 mt-2">Items collected by the collector</h6>
                                                                            :
                                                                            rh.status === "DELIVERED" ?
                                                                                <h6 className="mb-1 mt-2">Items delivered to the plant</h6>
                                                                                :
                                                                                rh.status === "RECYCLED" ?
                                                                                    <h6 className="mb-1 mt-2">Items recycled and ready to sale</h6>
                                                                                    :
                                                                                    rh.status === "SOLD" ?
                                                                                        <h6 className="mb-1 mt-2">Items sold to the buyer</h6>
                                                                                        : ""
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}