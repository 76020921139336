import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
export default function CollectorRequestDetails() {
    const navigate=useNavigate();
    const params = useParams();
    const [request, setRequest] = useState([]);
    const [driverId, setDriverId] = useState("00000000-0000-0000-0000-000000000000");
    const [drivers, setDrivers] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const AssignDriver = (e, requestId, driverId) => {
        if (driverId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select driver");
        }
        else if (window.confirm('Are you sure to assign this driver?')) {
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNREQUESTTODRIVER + "?RequestId=" + requestId + "&DriverId=" + driverId, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        navigate('/collectorrequests')
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setDriverId(value);
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTVIEWBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    const GetDrivers = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETCOLLECTORUSERS + "/" + localStorage.getItem('referenceId'), { ...headerconfig })
            .then((response) => {
                setDrivers(response.data.data);
            });
    };
    useEffect(() => {
        GetDrivers();
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Collection Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Primary Contact
                                            </th>
                                            <td colSpan={5}>
                                                {request.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Phone Number
                                            </th>
                                            <td colSpan={5}>
                                                {request.phone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Address
                                            </th>
                                            <td colSpan={5}>
                                                {request.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                            <th>
                                                Collector
                                            </th>
                                            <td>
                                                {request.collectorName}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Driver
                                            </th>
                                            <td>
                                                <select name="driverId" value={driverId} onChange={handleCollectorChange} className="form-select">
                                                    <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                    {drivers.map(rt =>
                                                        <option value={rt.id}>{rt.name}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td colSpan={2}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {btnSubmit === true ?
                                                        (<button type="button" onClick={e => AssignDriver(e, request.requestId, driverId)} className="btn btn-primary w-100">ASSIGN</button>) :
                                                        (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                                    }
                                                    <Link to={"/collectorrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td>

                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}