import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from "moment";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
const initialFieldValues = {
    invoiceId: "00000000-0000-0000-0000-000000000000",
    requestId: "00000000-0000-0000-0000-000000000000",
    invoiceNo: "",
    invoiceDate: new Date(),
    dueDate: new Date(),
    notes: "All accounts are to be paid within 7 days from receipt of invoice. To be paid by cheque or credit card or direct payment online.",
    billFrom: "Infinity, UK",
    billTo: "",
    shipTo: "",
    subTotal: 0,
    tax: 0,
    total: 0,
    status: "PENDING"
};
export default function ViewInvoice() {
    const params = useParams();
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [request, setRequest] = useState([]);
    const [items, setItems] = useState([]);
    const contentRef = useRef();
    const downloadPDF = () => {
        const input = contentRef.current;
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();
            const imgWidth = 210;
            const pageHeight = 297;
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
            let heightLeft = imgHeight;
            let position = 0;

            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('invoice.pdf');
        });
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETPICKUPREQUESTBYID + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
                if (response.data.data != null) {
                    setValues({
                        ...values,
                        ['billTo']: response.data.data.clientName + ", " + response.data.data.clientAddress,
                        ['shipTo']: response.data.data.clientName + ", " + response.data.data.clientAddress,
                    });
                    setItems(response.data.data.pickupRequestItems);
                    GetInvoiceDetails();
                }
            });
    };
    const GetInvoiceDetails = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETINVOICEBYREQUEST + "/" + params.pickupRequestId, { ...headerconfig })
            .then((response) => {
                setValues(response.data.data)
            });
    };
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.billFrom = values.billFrom === "" ? false : true;
        temp.billTo = values.billTo === "" ? false : true;
        temp.subTotal = values.subTotal === "" ? false : true;
        temp.total = values.total === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("invoiceId", values.invoiceId)
            formData.append("requestId", params.pickupRequestId)
            formData.append("invoiceNo", values.invoiceNo)
            formData.append("dueDate", values.dueDate)
            formData.append("invoiceDate", values.invoiceDate)
            formData.append("notes", values.notes)
            formData.append("billFrom", values.billFrom)
            formData.append("billTo", values.billTo)
            formData.append("shipTo", values.shipTo)
            formData.append("subTotal", values.subTotal)
            formData.append("tax", values.tax)
            formData.append("total", values.total)
            formData.append("status", values.status)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEINVOICE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEINVOICE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('invoiceId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.data);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.data);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Invoice</h4>
                                </div>
                            </div>
                        </div>
                            <div className="row justify-content-center">
                                <div className="col-xxl-12">
                                    <div className="card">
                                        <div ref={contentRef}>
                                        <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                            <div className="card-body border-bottom border-bottom-dashed p-4">
                                                <div className="row">
                                                    <div className="col-lg-4">
                                                        <div className="profile-user mx-auto  mb-3">
                                                            <span className="overflow-hidden border border-dashed d-flex align-items-center justify-content-center rounded" style={{ height: 80, width: 256 }}>
                                                                <img src="/assets/images/logo-dark.png" className="card-logo card-logo-dark user-profile-image img-fluid" alt="logo dark" />
                                                                <img src="/assets/images/logo-light.png" className="card-logo card-logo-light user-profile-image img-fluid" alt="logo light" />
                                                            </span>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <label htmlFor="companyAddress">From</label>
                                                            </div>
                                                            <div className="mb-2">
                                                                <textarea disabled type="text" value={values.billFrom} name="billFrom" rows={3} onChange={handleInputChange} className={"form-control bg-light border-0" + applyErrorClass('billFrom')} placeholder="Please enter bill from" />

                                                            </div>
                                                            <div>
                                                                <label htmlFor="companyAddress">To</label>
                                                            </div>
                                                            <div className="mb-2">
                                                                <textarea disabled type="text" value={values.billTo} name="billTo" rows={3} onChange={handleInputChange} className={"form-control bg-light border-0" + applyErrorClass('billTo')} placeholder="Please enter bill to" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/*end col*/}
                                                    <div className="col-lg-4 ms-auto">
                                                        <div>
                                                            <label htmlFor="companyAddress">Invoice No : {values.invoiceNo}</label>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="companyAddress">Invoice Date : {moment(values.invoiceDate).format('DD MMM YYYY')}</label>
                                                        </div>
                                                        <div>
                                                            <label htmlFor="companyAddress">Due Date : {moment(values.dueDate).format('DD MMM YYYY')}</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*end row*/}
                                            </div>
                                            <div className="card-body p-4">
                                                <div className="table-responsive">
                                                    <table className="table align-middle" id="customerTable">
                                                        <thead>
                                                            <tr>
                                                                <th>Item Name</th>
                                                                <th>Quantity</th>
                                                                <th>Weight</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {items.length > 0 && items.map(i =>
                                                                <tr>
                                                                    <td>{i.itemName}</td>
                                                                    <td>{i.quantity}</td>
                                                                    <td>{i.weight}</td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                        <tbody>
                                                            <tr className="border-top border-top-dashed mt-2">
                                                                <td colSpan={2} />
                                                                <td colSpan={1} className="p-0">
                                                                    <table className="table table-borderless table-sm table-nowrap align-middle mb-0">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th scope="row">Sub Total (EUR)</th>
                                                                                <td style={{ width: 150 }}>
                                                                                    <input disabled type="number" value={values.subTotal} name="subTotal" onChange={handleInputChange} className={"form-control bg-light border-0" + applyErrorClass('subTotal')} placeholder="subTotal" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th scope="row">Tax (EUR)</th>
                                                                                <td>
                                                                                    <input disabled type="number" value={values.tax} name="tax" onChange={handleInputChange} className={"form-control bg-light border-0" + applyErrorClass('tax')} placeholder="tax" />
                                                                                </td>
                                                                            </tr>
                                                                            <tr className="border-top border-top-dashed">
                                                                                <th scope="row">Total Amount (EUR)</th>
                                                                                <td>
                                                                                    <input disabled type="number" value={values.total} name="total" onChange={handleInputChange} className={"form-control bg-light border-0" + applyErrorClass('total')} placeholder="total" />
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    {/*end table*/}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                {/*end row*/}
                                                <div className="mt-4">
                                                    <label htmlFor="exampleFormControlTextarea1" className="form-label text-muted text-uppercase fw-semibold">NOTES</label>
                                                    <textarea disabled type="text" value={values.notes} name="notes" rows={2} onChange={handleInputChange} className={"form-control alert alert-info" + applyErrorClass('notes')} placeholder="Please enter bill to" />
                                                </div>
                                            </div>
                                        </form>
                                        </div>
                                        <div className="text-center d-flex align-items-right justify-content-right">
                                            <button onClick={downloadPDF} className="btn btn-md btn-primary fw-semibold mx-2">Download Invoice</button>
                                        </div>
                                    </div>
                                </div>
                                {/*end col*/}
                            </div>
                    </div>
                </div>
            </div>
        </div>
    );
}