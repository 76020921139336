import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
const initialFieldValues = {
    requestRecycleId: "00000000-0000-0000-0000-000000000000",
    requestId: "00000000-0000-0000-0000-000000000000",
    recycledItemId: "00000000-0000-0000-0000-000000000000",
    quantity: "",
    itemType: ""
};
export default function ClientRequestView() {
    const params = useParams();
    const [recycleItems, setRecycleItems] = useState([]);
    const [request, setRequest] = useState([]);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetRecycledItems = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTRECYCLEBYREQUESTID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRecycleItems(response.data.data.data);
            });
    };
    const handlePicked = () => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.REQUESTITEMDELIVERED + "?RequestId=" + params.requestId, { ...headerconfig })
            .then((response) => {
                GetRequest();
            });
    }
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    useEffect(() => {
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <div className="row text-nowrap p-3">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <b className="mr-2">Client Name</b> : {request.name}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <b className="mr-2">Status</b> : <span className="badge bg-success">{request.requestStatus}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <b>Phone Number</b> : {request.phone}
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row text-nowrap p-3">
                                    <div className="col-lg-12">
                                        <div className="mb-3">
                                            <b>Address</b> : {request.address}
                                        </div>
                                    </div>
                                </div>
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Collector
                                            </th>
                                            <td>
                                                {request.logisticCompanyName}
                                            </td>
                                            <th>Collector Assigned Date</th>
                                            <td>{moment(moment.utc(request.logisticAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                        </tr>
                                        {request.requestStatus === "PICKUP ASSIGNED" ?
                                            <tr>
                                                <th className="text-nowrap p-3" scope="row">
                                                    Pickup Status
                                                </th>
                                                <td>
                                                    Items not yet picked
                                                </td>
                                                <td><b className="mr-4">Is Items Picked?</b><button className="btn btn-primary" onClick={handlePicked}>Yes</button></td>
                                            </tr>
                                            :
                                            request.requestStatus === "DRIVER ASSIGNED" ?
                                                <>
                                                    <tr>
                                                        <th>
                                                            Driver
                                                        </th>
                                                        <td>
                                                            {request.driverName}
                                                        </td>
                                                        <th>Driver Assigned Date</th>
                                                        <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Pickup Status
                                                        </th>
                                                        <td>
                                                            Items not yet picked
                                                        </td>

                                                    </tr>
                                                </>
                                                : request.requestStatus === "COLLECTED" ?
                                                    <>
                                                        <tr>
                                                            <th>
                                                                Driver
                                                            </th>
                                                            <td>
                                                                {request.driverName}
                                                            </td>
                                                            <th>Driver Assigned Date</th>
                                                            <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Pickup Status
                                                            </th>
                                                            <td>
                                                                COLLECTED
                                                            </td>
                                                            <th>Pickup Date</th>
                                                            <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Delivery Status
                                                            </th>
                                                            <td>Not Yet Delivered</td>
                                                        </tr>
                                                    </>
                                                    : request.requestStatus === "DELIVERED" ?
                                                        <>
                                                            <tr>
                                                                <th>
                                                                    Driver
                                                                </th>
                                                                <td>
                                                                    {request.driverName}
                                                                </td>
                                                                <th>Driver Assigned Date</th>
                                                                <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Pickup Status
                                                                </th>
                                                                <td>
                                                                    COLLECTED
                                                                </td>
                                                                <th>Pickup Date</th>
                                                                <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Delivery Status
                                                                </th>
                                                                <td>
                                                                    {request.requestStatus}
                                                                </td>
                                                                <th>Delivered Date</th>
                                                                <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Recycled Status
                                                                </th>
                                                                <td>
                                                                    Items not yet recycled
                                                                </td>
                                                            </tr>
                                                        </>
                                                        :
                                                        request.requestStatus === "RECYCLED" ?
                                                            <>
                                                                <tr>
                                                                    <th>
                                                                        Driver
                                                                    </th>
                                                                    <td>
                                                                        {request.driverName}
                                                                    </td>
                                                                    <th>Driver Assigned Date</th>
                                                                    <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Pickup Status
                                                                    </th>
                                                                    <td>
                                                                        COLLECTED
                                                                    </td>
                                                                    <th>Pickup Date</th>
                                                                    <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Delivery Status
                                                                    </th>
                                                                    <td>
                                                                        DELIVERED
                                                                    </td>
                                                                    <th>Delivered Date</th>
                                                                    <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Recycled Status
                                                                    </th>
                                                                    <td>
                                                                        {request.requestStatus}
                                                                    </td>
                                                                    <th>Recycled Date</th>
                                                                    <td>{moment(moment.utc(request.recycledDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Sales Status
                                                                    </th>
                                                                    <td>
                                                                        Items ready for sale
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : request.requestStatus === "SOLD" ?
                                                                <>
                                                                    <tr>
                                                                        <th>
                                                                            Driver
                                                                        </th>
                                                                        <td>
                                                                            {request.driverName}
                                                                        </td>
                                                                        <th>Driver Assigned Date</th>
                                                                        <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Pickup Status
                                                                        </th>
                                                                        <td>
                                                                            COLLECTED
                                                                        </td>
                                                                        <th>Pickup Date</th>
                                                                        <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Delivery Status
                                                                        </th>
                                                                        <td>
                                                                            DELIVERED
                                                                        </td>
                                                                        <th>Delivered Date</th>
                                                                        <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Recycled Status
                                                                        </th>
                                                                        <td>
                                                                            RECYCLED
                                                                        </td>
                                                                        <th>Recycled Date</th>
                                                                        <td>{moment(moment.utc(request.recycledDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Sales Status
                                                                        </th>
                                                                        <td>
                                                                            {request.requestStatus}
                                                                        </td>
                                                                        <th>Sale Date</th>
                                                                        <td>{moment(moment.utc(request.saleDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                </>
                                                                : ""
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {localStorage.getItem('roleName') === "Client" ?
                                                        <Link to={"/clientrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                        :
                                                        <Link to={"/requests"} className="btn btn-danger w-100">Back to list</Link>}
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}