import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import auth from '../Configurations/Auth';
import moment from 'moment';
import axios from 'axios';
import { APIConfig } from '../Configurations/APIConfig';
export default function Header(props) {
  const [username, setUserName] = useState(localStorage.getItem('name'));
  const [isToggled, setIsToggled] = useState(false);
  const navigate = useNavigate();
  function CheckExpirationTime() {
    if (localStorage.getItem('tokenexpiration') !== '') {
      const expiredate = new Date(localStorage.getItem('tokenexpiration'));
      const localdate = new Date();
      if (expiredate > localdate) {
        return true;
      }
    }
    return false;
  }
  const showMenu = (event) => {
    if (isToggled) {
      document.body.classList.add('vertical-sidebar-enable');
    }
    else {
      document.body.classList.remove('vertical-sidebar-enable');
    }
    setIsToggled(!isToggled)
  }
  useEffect(() => {
    if (localStorage.getItem('userId') !== '') {
      setUserName(localStorage.getItem('name'));
      if (CheckExpirationTime()) {
        auth.uulogin();
      } else {
        navigate('/login');
      }
    }
  }, []);
  return (
    <header id="page-topbar">
      <div className="layout-width">
        <div className="navbar-header" style={{ backgroundColor: "#005045" }}>
          <div className="d-flex">
            <div className="navbar-brand-box horizontal-logo">
              <Link to={"/"} class="logo logo-dark">
                <span class="logo-sm">
                  <img src="/assets/images/logo.jpg" alt="" height="22"></img>
                </span>
                <span class="logo-lg">
                  <img src="/assets/images/logo.jpg" alt="" height="17"></img>
                </span>
              </Link>
            </div>
            <button type="button" onClick={showMenu} className="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger" id="topnav-hamburger-icon">
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>
          <div className="d-flex align-items-center">
            <div className="dropdown ms-sm-3 header-item topbar-user headerLogin">
              <button
                type="button"
                className="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span className="d-flex align-items-center">
                  <span className="text-start ms-xl-2">
                    <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text" style={{ color: '#fff' }}>
                      Hello, {username}<br /> {moment.utc().local().format('MMMM Do YYYY, h:mm a')}
                    </span>
                  </span>
                </span>
              </button>
              <div className="dropdown-menu dropdown-menu-end">
                <Link className="dropdown-item" to={"/profile"}>
                  <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1" />{" "}
                  <span className="align-middle">Profile</span>
                </Link>
                <Link className="dropdown-item" to={"/changepassword"}><i className="mdi mdi-lock text-muted fs-16 align-middle me-1" /> <span className="align-middle">Change Password</span></Link>
                <Link className="dropdown-item" to={"/logout"}>
                  <i className="mdi mdi-logout text-muted fs-16 align-middle me-1" />{" "}
                  <span className="align-middle" data-key="t-logout">
                    Logout
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
