import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function SideBar() {
  const [isToggled, setIsToggled] = useState(false);
  const showMenu = (event) => {
    if (isToggled) {
      document.body.classList.add('vertical-sidebar-enable');
    }
    else {
      document.body.classList.remove('vertical-sidebar-enable');
    }
    setIsToggled(!isToggled)
  }
  return (
    <div className="app-menu navbar-menu" style={{ backgroundColor: "#005045", border: "1px solid #005045" }}>
      {/* LOGO */}
      <div className="navbar-brand-box">
        {/* Dark Logo*/}
        <Link to="/" className="logo logo-dark">
          <span className="logo-sm">
            <img src="/assets/images/logo.jpg" alt height={40} />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo.jpg" alt height={50} />
          </span>
        </Link>
        {/* Light Logo*/}
        <Link to="/" className="logo logo-light">
          <span className="logo-sm">
            <img src="/assets/images/logo.jpg" alt height={22} />
          </span>
          <span className="logo-lg">
            <img src="/assets/images/logo.jpg" alt height={50} />
          </span>
        </Link>
        <button
          type="button"
          className="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i className="ri-record-circle-line" />
        </button>
      </div>
      <div id="scrollbar">
        <div className="container-fluid">
          <div id="two-column-menu" onClick={showMenu} style={{ color: "#fff" }}>Close menu</div>
          {localStorage.getItem('roleName') === 'Infinity' ?
            (<ul className="navbar-nav" id="navbar-nav">
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/superadmin/dashboard'}
                >
                  <i className="ri-dashboard-fill" />
                  <span data-key="t-dashboards">Dashboard</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/pickuprequests'} onClick={showMenu}
                >
                  <i className="ri-message-3-line" />
                  <span data-key="t-dashboards">Requests</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/superadmin/leads'}
                >
                  <i className="ri-user-line" />
                  <span data-key="t-dashboards">Leads</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/superadmin/clients'}
                >
                  <i className='ri-team-line'></i><span data-key="t-dashboards">Clients</span>
                </Link>
              </li>
              {/* <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/superadmin/collectors'}
                >
                  <i className='ri-truck-line'></i><span data-key="t-dashboards">Collectors</span>
                </Link>
              </li> */}
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/superadmin/buyers'}
                >
                  <i className='ri-group-line'></i><span data-key="t-dashboards">Buyers</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                  to={'/admin-reports'}
                >
                  <i className="ri-bar-chart-line" />
                  <span data-key="t-dashboards">Reports</span>
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} href="#Configuration" data-bs-toggle="collapse" role="button" aria-expanded={true} aria-controls="Configuration">
                  <i className="ri-wallet-2-line" /> <span data-key="t-authentication">Masters</span>
                </a>
                <div className="collapse menu-dropdown" id="Configuration">
                  <ul className="nav nav-sm flex-column">
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/superadmin/branches'}
                      >
                        <i className="ri-home-fill" />
                        <span data-key="t-dashboards">Branches</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/logisitics'} onClick={showMenu}
                      >
                        <i className="ri-user-fill" />
                        <span data-key="t-dashboards">Logisitics</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/superadmin/users'} onClick={showMenu}
                      >
                        <i className="ri-user-fill" />
                        <span data-key="t-dashboards">Users</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/groups'}
                      >
                        <i className="ri-group-2-fill" />
                        <span data-key="t-dashboards">Groups</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/grouptypes'}
                      >
                        <i className="ri-user-line" />
                        <span data-key="t-dashboards">Group Types</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/items'}
                      >
                        <i className="ri-shopping-cart-2-line" />
                        <span data-key="t-dashboards">Items</span>
                      </Link>
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/recycleitems'}
                      >
                        <i className="ri-shopping-cart-2-line" />
                        <span data-key="t-dashboards">Recycle Items</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>)
            :
            localStorage.getItem('roleName') === 'Client' ?
              (
                <ul className="navbar-nav" id="navbar-nav">
                  <li className="nav-item">
                    <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/clientdashboard'}>
                      <i className="ri-dashboard-fill" />
                      <span className="align-middle">Dashboard</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                      to={'/pickuprequests'}
                    >
                      <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                      <span data-key="t-dashboards">Requests</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/clientbranches'}>
                      <i className="ri-home-line" />
                      <span className="align-middle">Branches</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                      to={'/client-reports'}
                    >
                      <i className="ri-bar-chart-line" />
                      <span data-key="t-dashboards">Reports</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/clientusers'}>
                      <i className="ri-group-line" />
                      <span className="align-middle">Users</span>
                    </Link>
                  </li>
                </ul>
              )
              :
              localStorage.getItem('roleName') === 'ClientUser' ?
                (
                  <ul className="navbar-nav" id="navbar-nav">
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/clientuserrequests'}
                      >
                        <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                        <span data-key="t-dashboards">Requests</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/profile'}>
                        <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                        <span className="align-middle">Profile</span>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={"/changepassword"}>
                        <i className="mdi mdi-lock   fs-16 align-middle me-1" />
                        <span className="align-middle">Change Password</span></Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                        to={'/logout'}>
                        <i className="mdi mdi-logout   fs-16 align-middle me-1" />
                        <span data-key="t-dashboards">Logout</span>
                      </Link>
                    </li>
                  </ul>
                )
                :
                localStorage.getItem('roleName') === 'CollectorUser' ?
                  (
                    <ul className="navbar-nav" id="navbar-nav">
                      <li className="nav-item">
                        <Link
                          className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                          to={'/collectoruserrequests'}
                        >
                          <i className="ri-message-3-line" />
                          <span data-key="t-dashboards">Requests</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/profile'}>
                          <i className="mdi mdi-account-circle fs-16 align-middle me-1" />
                          <span className="align-middle">Profile</span>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={"/changepassword"}>
                          <i className="mdi mdi-lock fs-16 align-middle me-1" />
                          <span className="align-middle">Change Password</span></Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                          to={'/logout'}>
                          <i className="mdi mdi-logout fs-16 align-middle me-1" />
                          <span data-key="t-dashboards">Logout</span>
                        </Link>
                      </li>
                    </ul>
                  )
                  : localStorage.getItem('roleName') === 'Collector' ?
                    (
                      <ul className="navbar-nav" id="navbar-nav">
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/collectordashboard'}>
                            <i className="ri-dashboard-fill" />
                            <span className="align-middle">Dashboard</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                            to={'/collectorrequests'}
                          >
                            <i className="ri-message-3-line" />
                            <span data-key="t-dashboards">Requests</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/collectorbranches'}>
                            <i className="ri-home-line" />
                            <span className="align-middle">Branches</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/collectorusers'}>
                            <i className="ri-group-line" />
                            <span className="align-middle">Users</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/profile'}>
                            <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                            <span className="align-middle">Profile</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={"/changepassword"}>
                            <i className="mdi mdi-lock   fs-16 align-middle me-1" />
                            <span className="align-middle">Change Password</span></Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                            to={'/logout'}>
                            <i className="mdi mdi-logout   fs-16 align-middle me-1" />
                            <span data-key="t-dashboards">Logout</span>
                          </Link>
                        </li>
                      </ul>
                    )
                    :
                    localStorage.getItem('roleName') === 'Buyer' ?
                      (
                        <ul className="navbar-nav" id="navbar-nav">
                          <li className="nav-item">
                            <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/buyerdashboard'}>
                              <i className="ri-dashboard-fill" />
                              <span className="align-middle">Dashboard</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/buyerbranches'}>
                              <i className="ri-home-line" />
                              <span className="align-middle">Branches</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/buyerusers'}>
                              <i className="ri-group-line" />
                              <span className="align-middle">Users</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/profile'}>
                              <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                              <span className="align-middle">Profile</span>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={"/changepassword"}>
                              <i className="mdi mdi-lock   fs-16 align-middle me-1" />
                              <span className="align-middle">Change Password</span></Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                              to={'/logout'}>
                              <i className="mdi mdi-logout   fs-16 align-middle me-1" />
                              <span data-key="t-dashboards">Logout</span>
                            </Link>
                          </li>
                        </ul>
                      )
                      :
                      (<ul className="navbar-nav" id="navbar-nav">
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={'/profile'}>
                            <i className="mdi mdi-account-circle   fs-16 align-middle me-1" />
                            <span className="align-middle">Profile</span>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }} to={"/changepassword"}>
                            <i className="mdi mdi-lock   fs-16 align-middle me-1" />
                            <span className="align-middle">Change Password</span></Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            className="nav-link menu-link ovr-nav-links" style={{ color: '#fff' }}
                            to={'/logout'}>
                            <i className="mdi mdi-logout   fs-16 align-middle me-1" />
                            <span data-key="t-dashboards">Logout</span>
                          </Link>
                        </li>
                      </ul>
                      )
          }
        </div>
        {/* Sidebar */}
      </div>
    </div>
  );
}