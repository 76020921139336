import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
const defaultVideoImage = "/assets/images/video_default_thumb.png";
const initialFieldValues = {
    requestCollectId: "00000000-0000-0000-0000-000000000000",
    requestId: "00000000-0000-0000-0000-000000000000",
    userId: localStorage.getItem('userId'),
    itemId: "",
    itemType: "",
    quantity: "",
    imageName: "",
    imageFile: null,
    imageSrc: defaultVideoImage
};
export default function CollectorUserCollect() {
    const params = useParams();
    const navigate=useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [request, setRequest] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const showPreview = e => {
        if (e.target.files && e.target.files[0]) {
            let imageFile = e.target.files[0];
            const reader = new FileReader();
            reader.onload = x => {
                setValues({
                    ...values,
                    imageFile,
                    imageSrc: x.target.result
                })
            }
            reader.readAsDataURL(imageFile)
        }
        else {
            setValues({
                ...values,
                imageFile: null,
                imageSrc: defaultVideoImage
            })
        }
    }
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const ItemCollected = (e, request) => {
        const formData = new FormData()
        formData.append("requestCollectId", values.requestCollectId)
        formData.append("requestId", request.requestId)
        formData.append("userId", localStorage.getItem('userId'))
        formData.append("itemId", request.itemId)
        formData.append("itemType", request.itemType)
        formData.append("quantity", request.quantity)
        formData.append("imageFile", values.imageFile)
        formData.append("imageName", values.imageName)
        formData.append("imageSrc", values.imageSrc)
        addOrEdit(formData);
    }
    const addOrEdit = (formData) => {
        if (formData.get('requestCollectId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        navigate('/collectoruserrequests')
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.COLLECTREQUEST, newrecord, { ...headerconfig })
        };
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTCOLLECTORVIEWBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    useEffect(() => {
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Primary Contact
                                            </th>
                                            <td colSpan={5}>
                                                {request.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Phone Number
                                            </th>
                                            <td colSpan={5}>
                                                {request.phone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Address
                                            </th>
                                            <td colSpan={5}>
                                                {request.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Collector
                                            </th>
                                            <td>
                                                {request.collectorName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">Driver</th>
                                            <td>{request.driverName}</td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Upload Image
                                            </th>
                                            <td>
                                                <input id="image-uploader" name="videoSrc" className={"form-control-file"} type="file" onChange={showPreview} />
                                            </td>
                                            <td>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {btnSubmit === true ?
                                                        (<button type="button" onClick={e => ItemCollected(e, request)} className="btn btn-primary">COLLECTED</button>) :
                                                        (<button type="button" className="btn btn-primary" disabled>Please wait...</button>)
                                                    }
                                                    <Link to={"/collectoruserrequests"} className="btn btn-danger">Back to list</Link>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}