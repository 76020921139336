import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
export default function Requests() {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [requestIds, setRequestIds] = useState([]);
    const [collectorId, setCollectorId] = useState("00000000-0000-0000-0000-000000000000");
    const [collectors, setCollectors] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetCollectors = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLCOLLECTOR, { ...headerconfig })
            .then((response) => {
                setCollectors(response.data.data);
            });
    };
    const AssignCollector = (e, collectorId) => {
        e.preventDefault();
        if (collectorId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select collector");
        }
        else if (window.confirm('Are you sure to assign this collector?')) {
            setBtnSubmit(false)
            for (var i = 0; i < requestIds.length; i++) {
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNREQUESTTOCOLLECTOR + "?RequestId=" + requestIds[i] + "&CollectorId=" + collectorId, { ...headerconfig })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            handleSuccess("Requests Assigned");
                            GetRequests(pageNumber)
                        }
                        else {
                            handleError(response.data.data);
                        }
                    });
            };
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setCollectorId(value);
    };
    const GetLastPageData = () => {
        GetRequests(totalPages)
    }
    const GetFirstPageData = () => {
        GetRequests("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetRequests(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const handleCheckboxChange = (event, checkboxId) => {
        if (event.target.checked) {
            // If checkbox is checked, add its ID to the array
            setRequestIds((prevIds) => [...prevIds, checkboxId]);
        } else {
            // If checkbox is unchecked, remove its ID from the array
            setRequestIds((prevIds) =>
                prevIds.filter((id) => id !== checkboxId)
            );
        }
    };
    const GetRequests = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTS + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setRequests(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    useEffect(() => {
        GetRequests(pageNumber);
        GetCollectors();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm">
                                                <h4 className="mb-sm-0">Requests</h4>
                                            </div>
                                            <div class="col-sm-auto">
                                                <div class="d-flex flex-wrap align-items-start gap-2">
                                                    {requestIds.length > 0 ?
                                                        <button type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i class="ri-add-line align-bottom me-1"></i> Assign Collector</button>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Select</th>
                                                        <th>Id</th>
                                                        <th>Item </th>
                                                        <th>Quantity </th>
                                                        <th>Branch</th>
                                                        <th>Address</th>
                                                        <th>Client</th>
                                                        <th>Requested Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {requests.length > 0 && requests.map((gt, index) =>
                                                        <tr key={gt.requestId}>
                                                            <td>
                                                                <input type="checkbox" value={gt.requestId} disabled={gt.requestStatus !== "NEW" ? true : false}
                                                                    checked={requestIds.includes(gt.requestId)}
                                                                    onChange={(e) => handleCheckboxChange(e, gt.requestId)} />
                                                            </td>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.itemName}</td>
                                                            <td className="id">{gt.quantity}({gt.itemType})</td>
                                                            <td className="id">{gt.branchName}</td>
                                                            <td className="id">{gt.address}</td>
                                                            <td className="id">{gt.clientPrimaryContact}</td>
                                                            <td className="id">{moment(moment.utc(gt.requestedDate).toDate()).format('MM/DD/YYYY HH:mm a')}</td>
                                                            {gt.requestStatus === "NEW" ?
                                                                <td className="id"><span className="badge text-bg-primary">{gt.requestStatus}</span></td>
                                                                : gt.requestStatus === "PICKUP ASSIGNED" ?
                                                                    <td className="id"><span className="badge text-bg-success">{gt.requestStatus}</span></td>
                                                                    : gt.requestStatus === "Driver Assigned" ?
                                                                        <td className="id"><span className="badge text-bg-warning">{gt.requestStatus}</span></td>
                                                                        :
                                                                        <td className="id"><span className="badge text-bg-danger">{gt.requestStatus}</span></td>
                                                            }
                                                            <td>
                                                                {gt.requestStatus === "NEW" ?
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                            <Link className="btn btn-sm btn-primary" to={"/requestdetails/" + gt.requestId}>
                                                                                Assign Pickup
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                            <Link className="btn btn-sm btn-danger" to={"/requestview/" + gt.requestId}>
                                                                                View
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{requests.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="showModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-light p-3">
                            <h5 className="modal-title" id="exampleModalLabel" />
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" />
                        </div>
                        <form action="#">
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">Collectors</label>
                                    <select name="collectorId" value={collectorId} onChange={handleCollectorChange} className="form-select">
                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                        {collectors.map(rt =>
                                            <option value={rt.collectorId}>{rt.primaryContact}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    {btnSubmit === true ?
                                        (<button type="button" onClick={e => AssignCollector(e, collectorId)} className="btn btn-primary w-100">ASSIGN</button>) :
                                        (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}