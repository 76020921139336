import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
export default function RequestDetails() {
    const navigate = useNavigate();
    const params = useParams();
    const [request, setRequest] = useState([]);
    const [logisticId, setLogisticId] = useState("00000000-0000-0000-0000-000000000000");
    const [collectors, setCollectors] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const AssignCollector = (e, requestId, logisticId) => {
        if (logisticId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select pickup");
        }
        else if (window.confirm('Are you sure to assign this collector?')) {
            axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNREQUESTTOPICKUP + "?RequestId=" + requestId + "&LogisticId=" + logisticId, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        setBtnSubmit(false)
                        if (localStorage.getItem('roleName') === "Client") {
                            navigate('/clientrequests')
                        }
                        else {
                            navigate('/requests')
                        }
                    }
                    else {
                        handleError(response.data.data);
                    }
                });
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setLogisticId(value);
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    const GetLogistics = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLLOGISTIC, { ...headerconfig })
            .then((response) => {
                setCollectors(response.data.data);
            });
    };
    useEffect(() => {
        GetLogistics();
        GetRequest();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Primary Contact
                                            </th>
                                            <td colSpan={5}>
                                                {request.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Phone Number
                                            </th>
                                            <td colSpan={5}>
                                                {request.phone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Address
                                            </th>
                                            <td colSpan={5}>
                                                {request.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                            <th>
                                                Logistic
                                            </th>
                                            <td>
                                                <select name="logisticId" value={logisticId} onChange={handleCollectorChange} className="form-select">
                                                    <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                    {collectors.map(rt =>
                                                        <option value={rt.logisticId}>{rt.companyName}</option>
                                                    )}
                                                </select>
                                            </td>
                                            <td></td>
                                            <td></td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td>
                                                <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                    {btnSubmit === true ?
                                                        (<button type="button" onClick={e => AssignCollector(e, request.requestId, logisticId)} className="btn btn-primary w-100">ASSIGN</button>) :
                                                        (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                                    }
                                                    {localStorage.getItem('roleName') === "Client" ?
                                                        <Link to={"/clientrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                        : localStorage.getItem('roleName') === "Collector" ?
                                                        <Link to={"/collectorrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                        : localStorage.getItem('roleName') === "CollectorUser" ?
                                                        <Link to={"/collectoruserrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                        :
                                                        <Link to={"/requests"} className="btn btn-danger w-100">Back to list</Link>}
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}