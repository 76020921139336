import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
const initialFieldValues = {
    requestRecycleId: "00000000-0000-0000-0000-000000000000",
    requestId: "00000000-0000-0000-0000-000000000000",
    recycledItemId: "00000000-0000-0000-0000-000000000000",
    quantity: "",
    itemType: ""
};
export default function RequestView() {
    const params = useParams();
    const [recycleItems, setRecycleItems] = useState([]);
    const [requestRecycle, setRequestRecycle] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [request, setRequest] = useState([]);
    const [buyers, setBuyers] = useState([]);
    const [buyerId, setBuyerId] = useState("00000000-0000-0000-0000-000000000000");
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.itemName = values.itemName === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("requestRecycleId", values.requestRecycleId)
            formData.append("requestId", params.requestId)
            formData.append("recycledItemId", values.recycledItemId)
            formData.append("quantity", values.quantity)
            formData.append("itemType", values.itemType)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEREQUESTRECYCLE, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEREQUESTRECYCLE, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('requestRecycleId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetRecycleRequests(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetRecycleRequests(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetRecycleRequests(totalPages)
    }
    const GetFirstPageData = () => {
        GetRecycleRequests("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetRecycleRequests(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETEREQUESTRECYCLE + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetRecycleRequests(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetRecycleRequests(pageNumber);
                    }
                });
        }
    }
    const GetRequestItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLRECYCLEITEM, { ...headerconfig })
            .then((response) => {
                setRecycleItems(response.data.data);
            });
    };
    const GetRecycleRequests = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTRECYCLEBYREQUESTID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequestRecycle(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    const handleBuyerChange = (e) => {
        const { name, value } = e.target;
        setBuyerId(value);
    };
    const GetRequest = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETREQUESTVIEWBYID + "/" + params.requestId, { ...headerconfig })
            .then((response) => {
                setRequest(response.data.data);
            });
    };
    const handleDelivered = () => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.REQUESTITEMDELIVERED + "?RequestId=" + params.requestId, { ...headerconfig })
            .then((response) => {
                GetRequest();
            });
    }
    const handleRecycle = () => {
        axios
            .post(APIConfig.APIACTIVATEURL + APIConfig.REQUESTITEMRECYCLED + "?RequestId=" + params.requestId, { ...headerconfig })
            .then((response) => {
                GetRequest();
            });
    }
    const handleSold = () => {
        if (buyerId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select buyer");
        }
        else {
            axios
                .post(APIConfig.APIACTIVATEURL + APIConfig.REQUESTITEMSOLD + "?RequestId=" + params.requestId + "&BuyerId=" + buyerId + "&price=0", { ...headerconfig })
                .then((response) => {
                    GetRequest();
                });
        }
    }
    const GetBuyers = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLBUYER, { ...headerconfig })
            .then((response) => {
                setBuyers(response.data.data);
            });
    };
    useEffect(() => {
        GetRequest();
        GetBuyers();
        GetRecycleRequests();
        GetRequestItems();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Request Details</h4>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header align-items-center border-0 d-flex">
                            </div>
                            <div className="card-body p-0">
                                <table className="table table-bordered table-nowrap mb-0">
                                    <tbody>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Primary Contact
                                            </th>
                                            <td colSpan={5}>
                                                {request.name}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Phone Number
                                            </th>
                                            <td colSpan={5}>
                                                {request.phone}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Address
                                            </th>
                                            <td colSpan={5}>
                                                {request.address}
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="text-nowrap p-3" scope="row">
                                                Item
                                            </th>
                                            <td>
                                                {request.itemName}
                                            </td>
                                            <th className="text-nowrap p-3" scope="row">
                                                Quantity
                                            </th>
                                            <td>
                                                {request.quantity} ({request.itemType})
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>
                                                Collector
                                            </th>
                                            <td>
                                                {request.collectorName}
                                            </td>
                                            <th>Collector Assigned Date</th>
                                            <td>{moment(moment.utc(request.collectorAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                        </tr>
                                        {request.requestStatus === "COLLECTOR ASSIGNED" ?
                                            <tr>
                                                <th>
                                                    Driver
                                                </th>
                                                <td>
                                                    Driver not yet assigned
                                                </td>
                                            </tr>
                                            :
                                            request.requestStatus === "DRIVER ASSIGNED" ?
                                                <>
                                                    <tr>
                                                        <th>
                                                            Driver
                                                        </th>
                                                        <td>
                                                            {request.driverName}
                                                        </td>
                                                        <th>Driver Assigned Date</th>
                                                        <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>
                                                            Pickup Status
                                                        </th>
                                                        <td>
                                                            Items not yet picked
                                                        </td>
                                                    </tr>
                                                </>
                                                : request.requestStatus === "COLLECTED" ?
                                                    <>
                                                        <tr>
                                                            <th>
                                                                Driver
                                                            </th>
                                                            <td>
                                                                {request.driverName}
                                                            </td>
                                                            <th>Driver Assigned Date</th>
                                                            <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Pickup Status
                                                            </th>
                                                            <td>
                                                                COLLECTED
                                                            </td>
                                                            <th>Pickup Date</th>
                                                            <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>
                                                                Delivery Status
                                                            </th>
                                                            <td>Not Yet Delivered</td>
                                                            <td><button className="btn btn-primary" onClick={handleDelivered}>Delivered</button></td>
                                                        </tr>
                                                    </>
                                                    : request.requestStatus === "DELIVERED" ?
                                                        <>
                                                            <tr>
                                                                <th>
                                                                    Driver
                                                                </th>
                                                                <td>
                                                                    {request.driverName}
                                                                </td>
                                                                <th>Driver Assigned Date</th>
                                                                <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Pickup Status
                                                                </th>
                                                                <td>
                                                                    COLLECTED
                                                                </td>
                                                                <th>Pickup Date</th>
                                                                <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Delivery Status
                                                                </th>
                                                                <td>
                                                                    {request.requestStatus}
                                                                </td>
                                                                <th>Delivered Date</th>
                                                                <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                            </tr>
                                                            <tr>
                                                                <th>
                                                                    Recycled Status
                                                                </th>
                                                                <td>
                                                                    Items not yet recycled
                                                                </td>
                                                                <td><button className="btn btn-primary" onClick={handleRecycle}>Recycled</button></td>
                                                            </tr>
                                                        </>
                                                        :
                                                        request.requestStatus === "RECYCLED" ?
                                                            <>
                                                                <tr>
                                                                    <th>
                                                                        Driver
                                                                    </th>
                                                                    <td>
                                                                        {request.driverName}
                                                                    </td>
                                                                    <th>Driver Assigned Date</th>
                                                                    <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Pickup Status
                                                                    </th>
                                                                    <td>
                                                                        COLLECTED
                                                                    </td>
                                                                    <th>Pickup Date</th>
                                                                    <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Delivery Status
                                                                    </th>
                                                                    <td>
                                                                        DELIVERED
                                                                    </td>
                                                                    <th>Delivered Date</th>
                                                                    <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Recycled Status
                                                                    </th>
                                                                    <td>
                                                                        {request.requestStatus}
                                                                    </td>
                                                                    <th>Recycled Date</th>
                                                                    <td>{moment(moment.utc(request.recycledDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                </tr>
                                                                <tr>
                                                                    <th>
                                                                        Sales Status
                                                                    </th>
                                                                    <td>
                                                                        Items ready for sale
                                                                    </td>
                                                                    <th>Buyer</th>
                                                                    <td>
                                                                        <select name="collectorId" value={buyerId} onChange={handleBuyerChange} className="form-select">
                                                                            <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                                            {buyers.map(rt =>
                                                                                <option value={rt.buyerId}>{rt.primaryContact}</option>
                                                                            )}
                                                                        </select>
                                                                    </td>
                                                                    <td>
                                                                        <button className="btn btn-primary" onClick={handleSold}>SOLD</button>
                                                                    </td>
                                                                </tr>
                                                            </>
                                                            : request.requestStatus === "SOLD" ?
                                                                <>
                                                                    <tr>
                                                                        <th>
                                                                            Driver
                                                                        </th>
                                                                        <td>
                                                                            {request.driverName}
                                                                        </td>
                                                                        <th>Driver Assigned Date</th>
                                                                        <td>{moment(moment.utc(request.driverAssignedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Pickup Status
                                                                        </th>
                                                                        <td>
                                                                            COLLECTED
                                                                        </td>
                                                                        <th>Pickup Date</th>
                                                                        <td>{moment(moment.utc(request.driverPickedDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Delivery Status
                                                                        </th>
                                                                        <td>
                                                                            DELIVERED
                                                                        </td>
                                                                        <th>Delivered Date</th>
                                                                        <td>{moment(moment.utc(request.deliveredDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Recycled Status
                                                                        </th>
                                                                        <td>
                                                                            RECYCLED
                                                                        </td>
                                                                        <th>Recycled Date</th>
                                                                        <td>{moment(moment.utc(request.recycledDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>
                                                                            Sales Status
                                                                        </th>
                                                                        <td>
                                                                            {request.requestStatus}
                                                                        </td>
                                                                        <th>Sale Date</th>
                                                                        <td>{moment(moment.utc(request.saleDate).toDate()).format('DD MMM YYYY HH:mm a')}</td>
                                                                    </tr>
                                                                </>
                                                                : ""
                                        }
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <td colSpan={5}></td>
                                            <td>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {localStorage.getItem('roleName') === "Client" ?
                                                        <Link to={"/clientrequests"} className="btn btn-danger w-100">Back to list</Link>
                                                        :
                                                        <Link to={"/requests"} className="btn btn-danger w-100">Back to list</Link>}
                                                </div>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <div className="row mt-4">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0">Recycled Items</h4>
                            </div>
                        </div>
                        <div className="card">
                            <div className="alert alert-success">
                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="quantity" className="form-label">Items<span className="asterik">*</span></label>
                                                <select name="recycledItemId" value={values.recycledItemId} onChange={handleInputChange} className="form-select">
                                                    <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                    {recycleItems.map(rt =>
                                                        <option value={rt.recycledItemId}>{rt.itemName}</option>
                                                    )}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="quantity" className="form-label">Quantity<span className="asterik">*</span></label>
                                                <input type="text" value={values.quantity} name="quantity" onChange={handleInputChange} className={"form-control" + applyErrorClass('quantity')} placeholder="Please enter quantity" />
                                                {errors.quantity === false ? (<div className="validationerror">Please enter quantity </div>) : ('')}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="mb-3">
                                                <label htmlFor="itemType" className="form-label">ItemType<span className="asterik">*</span></label>
                                                <input type="text" value={values.itemType} name="itemType" onChange={handleInputChange} className={"form-control" + applyErrorClass('itemType')} placeholder="Please enter itemType" />
                                                {errors.itemType === false ? (<div className="validationerror">Please enter itemType </div>) : ('')}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                                {btnSubmit === true ?
                                                    (<button type="submit" className="btn btn-primary w-100">Submit</button>) :
                                                    (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                                }
                                                <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="card card-height-100">
                            <div className="card-body">
                                <div className="table-responsive table-card">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Id</th>
                                                <th>Quantity</th>
                                                <th>Item Type</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {requestRecycle.length > 0 && requestRecycle.map((gt, index) =>
                                                <tr key={gt.recycledItemId}>
                                                    <td>{index + 1}</td>
                                                    <td className="id">{gt.quantity}</td>
                                                    <td className="id">{gt.itemType}</td>
                                                    <td>
                                                        <ul className="list-inline hstack gap-2 mb-0">
                                                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                <Link className="edit-item-btn" onClick={e => showEditDetails(gt)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                            </li>
                                                            <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                <Link className="remove-item-btn" onClick={e => onDelete(e, gt.requestRecycleId)}>
                                                                    <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                </Link>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                                <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                    <div className="flex-shrink-0">
                                        <div className="text-muted">
                                            Showing <span className="fw-semibold">{requestRecycle.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                        </div>
                                    </div>
                                    <ul className="pagination pagination-separated pagination-sm mb-0">
                                        <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                            <Link className="page-link">Previous</Link>
                                        </li>
                                        {renderPageNumbers}
                                        <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                            <Link className="page-link">Next</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    );
}