import { APIConfig } from '../Common/Configurations/APIConfig';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { handleSuccess, handleError } from '../Common/Layouts/CustomAlerts';
const initialFieldValues = {
    clientId: "00000000-0000-0000-0000-000000000000",
    userId: "00000000-0000-0000-0000-000000000000",
    primaryContact: "",
    email: "",
    phoneNumber: "",
    address: "",
    groupId: "a5b89436-9120-4401-b340-c117bb0e7810",
    collectionNotes: "",
    password: ""
};
export default function Register() {
    const navigate = useNavigate();
    const [values, setValues] = useState(initialFieldValues);
    const [errors, setErrors] = useState({});
    const [btnSubmit, setBtnSubmit] = useState(true);
    const validate = () => {
        let temp = {};
        temp.groupId = values.groupId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.primaryContact = values.primaryContact === "" ? false : true;
        temp.email = values.email === "" ? false : true;
        temp.phoneNumber = values.phoneNumber === "" ? false : true;
        temp.password = values.password === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                setBtnSubmit(false);
                const formData = new FormData()
                formData.append("clientId", values.clientId)
                formData.append("groupId", "a5b89436-9120-4401-b340-c117bb0e7810")
                formData.append("primaryContact", values.primaryContact)
                formData.append("email", values.email)
                formData.append("phoneNumber", values.phoneNumber)
                formData.append("address", values.address)
                formData.append("password", values.password)
                formData.append("collectionNotes", values.collectionNotes)
                addOrEdit(formData)
            } catch (err) {
                handleError('Error' + err);
            }
        } else {
            handleError('Please check the mandatory fields');
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATECLIENT, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('clientId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.data);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] == false ? ' form-control-danger' : '';
    return (

        <div className="">
            <main className="homeSection" style={{ backgroundImage: "~/assets/images/background.jpg" }}>
                <img src="/assets/images/logo.jpg" className="homelogo" />
                <br></br>
                <span type="button" style={{paddingTop:"50px", paddingBottom:"20px", fontSize:"20px", color:"#fff", margin:"auto"}} disabled>CLIENT REGISTRATION</span>
                <form onSubmit={handleSubmit} autoComplete="off" noValidate className="loginForm">                
                    <div className="inputDivs">
                        <span className="material-symbols-outlined"> <i className="ri-user-line" /></span>
                        <input className={'loginInputs' + applyErrorClass('primaryContact')} name="primaryContact" type="text" value={values.primaryContact} onChange={handleInputChange} placeholder="Please enter name" />
                    </div>
                    <div className="inputDivs">
                        <span className="material-symbols-outlined"> <i className="ri-mail-line" /> </span>
                        <input className={'loginInputs' + applyErrorClass('email')} name="email" type="text" value={values.email} onChange={handleInputChange} placeholder="Please enter email" />
                    </div>
                    <div className="inputDivs">
                        <span className="material-symbols-outlined"> <i class="ri-cellphone-line"></i> </span>
                        <input className={'loginInputs' + applyErrorClass('phoneNumber')} name="phoneNumber" type="text" value={values.phoneNumber} onChange={handleInputChange} placeholder="Please enter phone number" />
                    </div>
                    <div className="inputDivs">
                        <span className="material-symbols-outlined"> <i className="ri-lock-line" /> </span>
                        <input type="password" className={'loginInputs' + applyErrorClass('password')} name="password" value={values.password} onChange={handleInputChange} placeholder="Please enter password" />
                    </div>
                    {btnSubmit === true ?
                        (<button type="submit" className="LoginBtn">Register as a Client</button>) :
                        (<button type="button" className="LoginBtn" disabled>Please wait...</button>)
                    }
                    <p className="loginMsg">Already have an account? <Link to="/" style={{color:"#fff"}}>Please click here for login.</Link></p>
                </form>
            </main>
        </div>
    );
}
