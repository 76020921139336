import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import moment from 'moment';
export default function CollectorRequests() {
    const [requests, setRequests] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetLastPageData = () => {
        GetRequests(totalPages)
    }
    const GetFirstPageData = () => {
        GetRequests("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetRequests(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const GetRequests = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETCOLLECTORREQUESTS + "/" + localStorage.getItem('referenceId') + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setRequests(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    useEffect(() => {
        GetRequests(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Requests</h4>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-header align-items-center d-flex">
                                        <h4 className="card-title mb-0 flex-grow-1">Requests</h4>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Item </th>
                                                        <th>Quantity </th>
                                                        <th>Client</th>
                                                        <th>Requested Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {requests.length > 0 && requests.map((gt, index) =>
                                                        <tr key={gt.requestId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.itemName}</td>
                                                            <td className="id">{gt.quantity}({gt.itemType})</td>
                                                            <td className="id">{gt.clientPrimaryContact}</td>
                                                            <td className="id">{moment(moment.utc(gt.requestedDate).toDate()).format('MM/DD/YYYY HH:mm a')}</td>
                                                            {gt.requestStatus === "COLLECTOR ASSIGNED" ?
                                                                <td className="id"><span className="badge text-bg-danger">Driver Not Assigned</span></td>
                                                                :
                                                                <td className="id"><span className="badge text-bg-success">{gt.requestStatus}</span></td>
                                                            }
                                                            <td>
                                                                {gt.requestStatus === "COLLECTOR ASSIGNED" ?
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Assign">
                                                                            <Link className="btn btn-sm btn-success" to={"/collectorrequestdetails/" + gt.requestId}>
                                                                                Assign Driver
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                            <Link className="btn btn-sm btn-danger" to={"/collectorrequestview/" + gt.requestId}>
                                                                                View
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                }

                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{requests.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}