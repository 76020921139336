import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
const initialFieldValues = {
    pickupRequestId: "00000000-0000-0000-0000-000000000000",
    clientId: "00000000-0000-0000-0000-000000000000",
    pickupRequestedDate: new Date(),
    notes: "",
    latitude: 0,
    longitude: 0,
    address: "",
    status: "NEW",
    logisticId: "00000000-0000-0000-0000-000000000000",
    pickupRequestItems: []
};
const itemsInitialFieldValues = {
    pickupRequestId: "00000000-0000-0000-0000-000000000000",
    itemId: "00000000-0000-0000-0000-000000000000",
    itemName: "",
    quantity: 0,
    weight: "",
};
export default function ClientPickupRequest() {
    const navigate = useNavigate();
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [items, setItems] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [itemValues, setItemValues] = useState(itemsInitialFieldValues);
    const [itemItineraryState, setItemItineraryState] = useState([]);
    //Function to add a new item to the array
    const addHIItem = (index) => {
        const newItem = { id: itemItineraryState.length + 1, itemId: itemValues.itemId, itemName: itemValues.itemName, weight: itemValues.weight, quantity: itemValues.quantity };
        console.log(newItem)
        setItemItineraryState([...itemItineraryState, newItem]);
        setItemValues(itemsInitialFieldValues)
    };
    // Function to remove an item from the array by ID
    const removeHIItem = (id) => {
        console.log(id)
        const updatedItems = itemItineraryState.filter(item => item.id !== id);
        setItemItineraryState(updatedItems);
    };
    const [errors, setErrors] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const [locations, setLocations] = useState([]);
    const getLocation = async (location) => {
        const response = await axios.get(APIConfig.APIACTIVATEURL + 'home/location?input=' + location);
        setLocations(response.data.data)
    }
    const handleLocationChange = (e) => {
        const value = e.target.value
        if (value.trim().length > 2) {
            getLocation(value)
        }
        setValues({
            ...values,
            ['address']: value,
        });
    }
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const selectLocationHandle = (data) => {
        setValues({
            ...values,
            ['address']: data.address,
            ['latitude']: data.latitude,
            ['longitude']: data.longitude
        });
        setLocations('')
    }
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const handleItemInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "itemId") {
            var index = e.nativeEvent.target.selectedIndex;
            setItemValues({
                ...itemValues,
                ['itemName']: e.nativeEvent.target[index].text,
                ['itemId']: value,
            });
        }
        else {
            setItemValues({
                ...itemValues,
                [name]: value,
            });
        }
    };
    const validate = () => {
        let temp = {};
        temp.address = values.address === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (itemItineraryState.length > 0) {
            if (validate()) {
                setBtnSubmit(false);
                const formData = new FormData()
                formData.append("pickupRequestId", values.pickupRequestId)
                formData.append("clientId", localStorage.getItem('referenceId'))
                formData.append("notes", values.notes)
                formData.append("status", values.status)
                formData.append("address", values.address)
                formData.append("latitude", values.latitude)
                formData.append("longitude", values.longitude)
                formData.append("pickupRequestItems", JSON.stringify(itemItineraryState))
                formData.append("pickupRequestedDate", moment(values.pickupRequestedDate).format('DD MMM YYYY'))
                addOrEdit(formData);
            }
        } else {
            alert('Please add pickup items');
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEPICKUPREQUEST, newrecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('pickupRequestId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        navigate('/pickuprequests')
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const GetItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLITEM, { ...headerconfig })
            .then((response) => {
                setItems(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetItems();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="col-xl-12">
                            <div className="card card-height-100">
                                <div className="card-header">
                                    <div className="col-sm">
                                        <h4 className="mb-sm-0">New Pickup Request</h4>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <table className="table align-middle" id="customerTable">
                                        <thead className="table-light">
                                            <tr>
                                                <th>Items</th>
                                                <th>Quantity</th>
                                                <th>Weight</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody className="list form-check-all">
                                            {itemItineraryState.length > 0 && itemItineraryState.map((v, index) =>
                                                <tr key={index}>
                                                    <td>{v.itemName}</td>
                                                    <td>{v.quantity}</td>
                                                    <td>{v.weight}</td>
                                                    <td>
                                                        <Link className="remove-item-btn" onClick={e => removeHIItem(index + 1)}>
                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td>
                                                    <select name="itemId" value={itemValues.itemId} onChange={handleItemInputChange} className={"form-select" + applyErrorClass('itemId')}>
                                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                        {items.map(rt =>
                                                            <option value={rt.itemId}>{rt.itemName}</option>
                                                        )}
                                                    </select>
                                                    {errors.itemId === false ? (<div className="validationerror">Please select item </div>) : ('')}
                                                </td>
                                                <td>
                                                    <input type="text" value={itemValues.quantity} className="form-control" onChange={handleItemInputChange} name="quantity"></input>
                                                </td>
                                                <td>
                                                    <input type="text" value={itemValues.weight} className="form-control" onChange={handleItemInputChange} name="weight" placeholder="Kgs, lbs"></input>
                                                </td>
                                                <td>
                                                    <button onClick={addHIItem} className="btn btn-sm btn-primary"> Add</button>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                    <div className="alert alert-success mt-2">
                                        <div className="row">
                                            <div class="col-lg-3">
                                                <label for="nameInput" class="form-label">Pickup Dates</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        options={{
                                                            dateFormat: "m-d-Y",
                                                        }}
                                                        value={values.pickupRequestedDate}
                                                        onChange={(selectedDates) => {
                                                            setValues({
                                                                ...values,
                                                                ['pickupRequestedDate']: selectedDates[0],
                                                            });
                                                        }}
                                                        name="pickupRequestedDate"
                                                    />
                                                    <div className="input-group-text bg-primary border-primary text-white">
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-4">
                                                <div className="mb-3">
                                                    <label htmlFor="address" className="form-label">Address</label>
                                                    <input type="text" value={values.address} name="address" onChange={handleLocationChange} className={"form-control" + applyErrorClass('address')} placeholder="Please enter address" />
                                                    {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                                    {locations[0] && <ul className="locationListVideo">
                                                        {locations.map(location => <li className="locationListVideoItem" onClick={selectLocationHandle.bind(null, location)}>{location.address}</li>)}
                                                    </ul>}
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <div className="mb-3">
                                                    <label htmlFor="notes" className="form-label">Notes</label>
                                                    <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Please enter notes" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {btnSubmit === true ?
                                                (<button type="submit" className="btn btn-primary w-100">Submit</button>) :
                                                (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}