import { Navbar, Nav, Container } from 'react-bootstrap';
const Navigation =()=>{
    return(
        <>
        <Navbar collapseOnSelect fixed='top' expand='sm' >
          <Container>
          <Navbar.Toggler/>
          <Navbar.Collapse>
            <Nav className="mr-auto" navbar>
              <Nav.Link href="#">Home</Nav.Link>
              <Nav.Link href="#">Login</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          </Container>
        </Navbar>
        </>
    )
}
export default Navigation;