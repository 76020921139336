import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
const initialFieldValues = {
    requestId: "00000000-0000-0000-0000-000000000000",
    itemId: "00000000-0000-0000-0000-000000000000",
    quantity: 0,
    itemType: "",
    userId: "00000000-0000-0000-0000-000000000000",
    collectorId: "00000000-0000-0000-0000-000000000000",
    driverId: "00000000-0000-0000-0000-000000000000",
    requestStatus: "NEW",
    notes:"",
    latitude:0,
    longitude:0,
    address: "",
};
export default function ClientRequests() {
    const navigate = useNavigate();
    const [requests, setRequests] = useState([]);
    const [pickUpDate, setPickUpDate] = useState(new Date());
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [requestIds, setRequestIds] = useState([]);
    const [collectorId, setCollectorId] = useState("00000000-0000-0000-0000-000000000000");
    const [collectors, setCollectors] = useState([]);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const [items, setItems] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    const GetCollectors = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLCOLLECTOR, { ...headerconfig })
            .then((response) => {
                setCollectors(response.data.data);
            });
    };
    const AssignCollector = (e, collectorId) => {
        e.preventDefault();
        if (collectorId === "00000000-0000-0000-0000-000000000000") {
            alert("Please select collector");
        }
        else if (window.confirm('Are you sure to assign this collector?')) {
            setBtnSubmit(false)
            for (var i = 0; i < requestIds.length; i++) {
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.ASSIGNREQUESTTOCOLLECTOR + "?RequestId=" + requestIds[i] + "&CollectorId=" + collectorId, { ...headerconfig })
                    .then((response) => {
                        if (response.data.statusCode === 200) {
                            handleSuccess("Requests Assigned");
                            GetRequests(pageNumber)
                        }
                        else {
                            handleError(response.data.data);
                        }
                    });
            };
            
        }
    }
    const handleCollectorChange = (e) => {
        const { name, value } = e.target;
        setCollectorId(value);
    };
    const GetLastPageData = () => {
        GetRequests(totalPages)
    }
    const GetFirstPageData = () => {
        GetRequests("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
            GetRequests(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const handleCheckboxChange = (event, checkboxId) => {
        if (event.target.checked) {
            // If checkbox is checked, add its ID to the array
            setRequestIds((prevIds) => [...prevIds, checkboxId]);
        } else {
            // If checkbox is unchecked, remove its ID from the array
            setRequestIds((prevIds) =>
                prevIds.filter((id) => id !== checkboxId)
            );
        }
    };
    const [locations, setLocations] = useState([]);
    const getLocation = async (location) => {
        const response = await axios.get(APIConfig.APIACTIVATEURL+'home/location?input=' + location);
        setLocations(response.data.data)
    }
    const handleLocationChange = (e) => {
        const value = e.target.value
        if (value.trim().length > 2) {
            getLocation(value)
        }
        setValues({
            ...values,
            ['address']: value,
        });
    }
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const selectLocationHandle = (data) => {
        setValues({
            ...values,
            ['address']: data.address,
            ['latitude']: data.latitude,
            ['longitude']: data.longitude
        });
        setLocations('')
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.itemId = values.itemId === "00000000-0000-0000-0000-000000000000" ? false : true;
        temp.quantity = values.quantity === 0 ? false : true;
        temp.itemType = values.itemType === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("requestId", values.requestId)
            formData.append("itemId", values.itemId)
            formData.append("itemType", values.itemType)
            formData.append("quantity", values.quantity)
            formData.append("userId", localStorage.getItem('userId'))
            formData.append("clientId", localStorage.getItem('referenceId'))
            formData.append("collectorId", values.collectorId)
            formData.append("driverId", values.driverId)
            formData.append("requestStatus", values.requestStatus)
            formData.append("notes", values.notes)
            formData.append("address", values.address)
            formData.append("latitude", values.latitude)
            formData.append("longitude", values.longitude)
            formData.append("pickUpDate", moment(values.pickUpDate).format('DD MMM YYYY'))
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEREQUEST, newrecord, { ...headerconfig }),
                update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEREQUEST, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('requestId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetRequests(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
        else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetRequests(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const GetRequests = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETCLIENTREQUESTS + "/" + localStorage.getItem('referenceId') + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setRequests(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const GetItems = () => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETALLITEM, { ...headerconfig })
            .then((response) => {
                setItems(response.data.data);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetItems();
        GetRequests(pageNumber);
        GetCollectors();
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                    <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="itemId" className="form-label">Items<span className="asterik">*</span></label>
                                            <select name="itemId" value={values.itemId} onChange={handleInputChange} className={"form-select" + applyErrorClass('itemId')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                {items.map(rt =>
                                                    <option value={rt.itemId}>{rt.itemName}</option>
                                                )}
                                            </select>
                                            {errors.itemId === false ? (<div className="validationerror">Please select item </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="itemType" className="form-label">Weight<span className="asterik">*</span></label>
                                            <select name="itemType" value={values.itemType} onChange={handleInputChange} className={"form-select" + applyErrorClass('itemType')}>
                                                <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                                    <option value="Kgs">(Kilograms) Kgs</option>
                                                    <option value="lbs">(Pounds) lbs</option>
                                                    <option value="tons">(Metric Tons)t</option>
                                            </select>
                                            {errors.itemType === false ? (<div className="validationerror">Please select type</div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="quantity" className="form-label">Quantity<span className="asterik">*</span></label>
                                            <input type="number" value={values.quantity} name="quantity" onChange={handleInputChange} className={"form-control" + applyErrorClass('email')} placeholder="Please enter quantity" />
                                            {errors.quantity === false ? (<div className="validationerror">Please enter quantity </div>) : ('')}
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                                <label for="nameInput" class="form-label">Pickup Date</label>
                                                <div className="input-group">
                                                    <Flatpickr
                                                        className="form-control border-0 dash-filter-picker shadow"
                                                        options={{
                                                            dateFormat: "d-m-Y",
                                                            minDate:new Date()
                                                        }}
                                                        value={pickUpDate}
                                                        onChange={(selectedDates) => {
                                                            setPickUpDate(selectedDates[0])
                                                        }}
                                                        name="pickUpDate"
                                                    />
                                                    <div className="input-group-text bg-primary border-primary text-white">
                                                        <i className="ri-calendar-2-line" />
                                                    </div>
                                                </div>
                                            </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="address" className="form-label">Address</label>
                                            <input type="text" value={values.address} name="address" onChange={handleLocationChange} className={"form-control" + applyErrorClass('address')} placeholder="Please enter address" />
                                            {errors.address === false ? (<div className="validationerror">Please enter address </div>) : ('')}
                                            {locations[0] && <ul className="locationListVideo">
                                            {locations.map(location => <li className="locationListVideoItem" onClick={selectLocationHandle.bind(null, location)}>{location.address}</li>)}
                                        </ul>}
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="mb-3">
                                            <label htmlFor="notes" className="form-label">Notes</label>
                                            <input type="text" value={values.notes} name="notes" onChange={handleInputChange} className={"form-control" + applyErrorClass('notes')} placeholder="Please enter notes" />
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                            {btnSubmit === true ?
                                                (<button type="submit" className="btn btn-primary w-100">Submit</button>) :
                                                (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                            }
                                            <button type="button" className="btn btn-danger w-100" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                <div className="card-header">
                                        <div className="row g-4 align-items-center">
                                            <div className="col-sm">
                                                <h4 className="mb-sm-0">Requests</h4>
                                            </div>
                                            <div class="col-sm-auto">
                                                <div class="d-flex flex-wrap align-items-start gap-2">
                                                    {requestIds.length > 0 ?
                                                        <button type="button" class="btn btn-success add-btn" data-bs-toggle="modal" id="create-btn" data-bs-target="#showModal"><i class="ri-add-line align-bottom me-1"></i> Assign Collector</button>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>{/* end card header */}
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th></th>
                                                        <th>Id</th>
                                                        <th>Item </th>
                                                        <th>Quantity </th>
                                                        <th>User</th>
                                                        <th>Phone</th>
                                                        <th>Client</th>
                                                        <th>Requested Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {requests.length > 0 && requests.map((gt, index) =>
                                                        <tr key={gt.requestId}>
                                                            <td>
                                                                <input type="checkbox" value={gt.requestId} disabled={gt.requestStatus !== "NEW" ? true : false}
                                                                    checked={requestIds.includes(gt.requestId)}
                                                                    onChange={(e) => handleCheckboxChange(e, gt.requestId)} />
                                                            </td>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.itemName}</td>
                                                            <td className="id">{gt.quantity}({gt.itemType})</td>
                                                            <td className="id">{gt.name}</td>
                                                            <td className="id">{gt.phone}</td>
                                                            <td className="id">{gt.clientPrimaryContact}</td>
                                                            <td className="id">{moment(moment.utc(gt.requestedDate).toDate()).format('MM/DD/YYYY HH:mm a')}</td>
                                                            {gt.requestStatus === "NEW" ?
                                                                <td className="id"><span className="badge text-bg-primary">{gt.requestStatus}</span></td>
                                                                : gt.requestStatus === "PICKUP ASSIGNED" ?
                                                                    <td className="id"><span className="badge text-bg-success">{gt.requestStatus}</span></td>
                                                                    : gt.requestStatus === "Driver Assigned" ?
                                                                        <td className="id"><span className="badge text-bg-warning">{gt.requestStatus}</span></td>
                                                                        :
                                                                        <td className="id"><span className="badge text-bg-danger">{gt.requestStatus}</span></td>
                                                            }
                                                            <td>
                                                                {gt.requestStatus === "NEW" ?
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Assign">
                                                                            <Link className="btn btn-sm btn-success" to={"/requestdetails/" + gt.requestId}>
                                                                                Assign Collector
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                    :
                                                                    <ul className="list-inline hstack gap-2 mb-0">
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="View">
                                                                            <Link className="btn btn-sm btn-danger" to={"/clientrequestview/" + gt.requestId}>
                                                                                View
                                                                            </Link>
                                                                        </li>
                                                                        <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Track">
                                                                            <Link className="btn btn-sm btn-primary" to={"/trackstatus/" + gt.requestId}>
                                                                                Track
                                                                            </Link>
                                                                        </li>
                                                                    </ul>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{requests.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="showModal" tabIndex={-1} aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header bg-light p-3">
                            <h5 className="modal-title" id="exampleModalLabel" />
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal" />
                        </div>
                        <form action="#">
                            <div className="modal-body">
                                <div className="mb-3">
                                    <label htmlFor="customername-field" className="form-label">Collectors</label>
                                    <select name="collectorId" value={collectorId} onChange={handleCollectorChange} className="form-select">
                                        <option value="00000000-0000-0000-0000-000000000000">Please Select</option>
                                        {collectors.map(rt =>
                                            <option value={rt.collectorId}>{rt.primaryContact}</option>
                                        )}
                                    </select>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <div className="hstack gap-2 justify-content-end">
                                    <button type="button" className="btn btn-light" data-bs-dismiss="modal">Close</button>
                                    {btnSubmit === true ?
                                        (<button type="button" data-bs-dismiss="modal" onClick={e => AssignCollector(e, collectorId)} className="btn btn-primary w-100">ASSIGN</button>) :
                                        (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                    }
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}