import Footer from '../Common/Layouts/Footer';
import Header from '../Common/Layouts/Header';
import SideBar from '../Common/Layouts/SideBar';
import CountUp from 'react-countup';
import { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { APIConfig } from '../Common/Configurations/APIConfig';
import { Link } from 'react-router-dom';
import ReactApexChart from "react-apexcharts";
import React from 'react'
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
export default function CollectorDashboard() {
  const [requests, setRequests] = useState([]);
  const [branches, setBranches] = useState(0);
  const [users, setUsers] = useState(0);
  const [newRequests, setNewRequests] = useState(0);
  const [pendingCollected, setPendingCollected] = useState(0);
  const [collected, setCollected] = useState(0);
  const headerconfig = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('userToken'),
      'Content-Type': 'application/json',
    },
  };
  const GetMapsRequests = () => {
    axios
      .get(APIConfig.APIACTIVATEURL + APIConfig.GETCOLLECTORREQUESTS + "/" + localStorage.getItem('referenceId'), {
        ...headerconfig,
      })
      .then((response) => {
        setRequests(response.data.data.data);
      });
  };
  const GetDataCounts = () => {
    axios
      .get(APIConfig.APIACTIVATEURL + APIConfig.GETCOLLECTORCOUNT + "?ReferenceId=" + localStorage.getItem('referenceId'), {
        ...headerconfig,
      })
      .then((response) => {
        setBranches(response.data.clientBranches);
        setUsers(response.data.clientUsers);
        setNewRequests(response.data.newRequests);
        setPendingCollected(response.data.driverNotAssgined);
        setCollected(response.data.collected);
      });
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDWVA34PTvtvFaM6D4RT8HeG9Ykq6BZV0M"
  })
  const containerStyle = {
    width: '1200px',
    height: '400px'
  };

  const center = {
    lat: -3.745,
    lng: -38.523
  };
  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    //map.fitBounds(bounds);
    map.setZoom(16);
    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  useEffect(() => {
    GetDataCounts();
    GetMapsRequests();
  }, []);
  return (
    <div id="layout-wrapper">
      <Header></Header>
      <SideBar></SideBar>
      <div className="main-content">
        <div className="page-content">
          <div className="container-fluid">
            {/* start page title */}
            <div className="row">
              <div className="col-12">
                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                  <h4 className="mb-sm-0">Hello, {localStorage.getItem("name")}</h4>
                  <div className="page-title-right">
                    <ol className="breadcrumb m-0">
                      <li className="breadcrumb-item">Dashboard</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
            {/* end page title */}
            <div className="row">
              <div className="col-xl-12">
                <div className="card crm-widget">
                  <div className="card-body p-0">
                    <div className="row row-cols-xxl-5 row-cols-md-3 row-cols-1 g-0">
                      <div className="col" style={{ backgroundColor: "#85C300" }}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{ color: "#fff" }}>Branches</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{ color: "#fff" }}>
                              <i className="ri-store-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3" style={{ color: "#fff" }}>
                              <h2 className="mb-0" style={{ color: "#fff" }}>{branches}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{ backgroundColor: "#85C300" }}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{ color: "#fff" }}>Branch Users</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{ color: "#fff" }}>
                              <i className="ri-user-2-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{ color: "#fff" }}>{users}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{ backgroundColor: "#85C300" }}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{ color: "#fff" }}>New Requests</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{ color: "#fff" }}>
                              <i className="ri-message-3-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{ color: "#fff" }}>{newRequests}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{ backgroundColor: "#85C300" }}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{ color: "#fff" }}>Driver Not Assgined</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{ color: "#fff" }}>
                              <i className="ri-truck-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{ color: "#fff" }}>{pendingCollected}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col" style={{ backgroundColor: "#85C300" }}>
                        <div className="py-4 px-3">
                          <h5 className="text-uppercase fs-13" style={{ color: "#fff" }}>Collected</h5>
                          <div className="d-flex align-items-center">
                            <div className="flex-shrink-0" style={{ color: "#fff" }}>
                              <i className="ri-team-line display-6" />
                            </div>
                            <div className="flex-grow-1 ms-3">
                              <h2 className="mb-0" style={{ color: "#fff" }}>{collected}</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>{/* end row */}
                  </div>{/* end card body */}
                </div>{/* end card */}
              </div>{/* end col */}
            </div>
            {isLoaded ?
              <div className="row">
                <div className="col-xl-12">
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={18}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                  >
                    {requests.map(marker => (
                      <Marker
                        position={{ lat: marker.latitude, lng: marker.longitude }}
                        key={marker.requestId}
                      />
                    ))}
                    <></>
                  </GoogleMap>
                </div>
              </div> : ""}
          </div>
          {/* container-fluid */}
        </div>
      </div>
    </div>
  );
}
