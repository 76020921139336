import LayoutHeader from "../Common/Layouts/Header";
import LayoutSideBar from "../Common/Layouts/SideBar";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from 'axios';
import { APIConfig } from "../Common/Configurations/APIConfig";
import { handleSuccess, handleError } from "../Common/Layouts/CustomAlerts";
const initialFieldValues = {
    itemId: "00000000-0000-0000-0000-000000000000",
    itemName: "",
    itemDescription: "",
    cO2: "",
    oZone: ""
};
export default function Item() {
    const [items, setItems] = useState([]);
    const [values, setValues] = useState(initialFieldValues);
    const [recordForEdit, setRecordForEdit] = useState(null);
    const [errors, setErrors] = useState({});
    const [totalPages, setTotalPages] = useState(0);
    const [pageSize, setPageSize] = useState(20);
    const [pageNumber, setPageNumber] = useState(1);
    const [data, setData] = useState([]);
    const [totalRecords, setTotalRecords] = useState(0);
    const [btnSubmit, setBtnSubmit] = useState(true);
    const headerconfig = {
        headers: {
            Authorization: "Bearer " + localStorage.getItem("userToken")
        }
    }
    useEffect(() => {
        if (recordForEdit !== null) {
            setValues(recordForEdit);
        }
    }, [recordForEdit]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };
    const validate = () => {
        let temp = {};
        temp.itemName = values.itemName === "" ? false : true;
        setErrors(temp);
        return Object.values(temp).every((x) => x === true);
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        if (validate()) {
            setBtnSubmit(false);
            const formData = new FormData()
            formData.append("itemId", values.itemId)
            formData.append("itemName", values.itemName)
            formData.append("itemDescription", values.itemDescription)
            formData.append("cO2", values.cO2)
            formData.append("oZone", values.oZone)
            addOrEdit(formData);
        }
    };
    const applicationAPI = () => {
        return {
            create: (newrecord) =>
                axios.post(APIConfig.APIACTIVATEURL + APIConfig.CREATEITEM, newrecord, { ...headerconfig }),
            update: (updateRecord) =>
                axios.put(APIConfig.APIACTIVATEURL + APIConfig.UPDATEITEM, updateRecord, { ...headerconfig })
        };
    };
    const addOrEdit = (formData) => {
        if (formData.get('itemId') === "00000000-0000-0000-0000-000000000000") {
            applicationAPI()
                .create(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Created");
                        resetForm();
                        GetItems(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        } else {
            applicationAPI()
                .update(formData)
                .then((res) => {
                    if (res.data.statusCode === 200) {
                        handleSuccess("Record Updated");
                        resetForm();
                        GetItems(pageNumber);
                        setBtnSubmit(true);
                    }
                    else {
                        handleError(res.data.message);
                        setBtnSubmit(true);
                    }
                });
        }
    };
    const resetForm = () => {
        setValues(initialFieldValues);
        setErrors({});
    };
    const showEditDetails = (data) => {
        setRecordForEdit(data);
    };
    const GetLastPageData = () => {
        GetItems(totalPages)
    }
    const GetFirstPageData = () => {
        GetItems("1")
    }
    const GetPageData = (number) => {
        setPageNumber(number);
        if (pageNumber !== number)
        GetItems(number)
    }
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }
    const renderPageNumbers = pageNumbers.map((number) => {
        return (
            <li className="page-item" key={number} id={number} onClick={() => GetPageData(number)}>
                <Link className="page-link">{number}</Link>
            </li>
        );
    });
    const onDelete = (e, id) => {
        if (window.confirm('Are you sure to delete this record?')) {
            axios.delete(APIConfig.APIACTIVATEURL + APIConfig.DELETEITEM + "/" + id, { ...headerconfig })
                .then((response) => {
                    if (response.data.statusCode === 200) {
                        handleSuccess(response.data.data);
                        resetForm();
                        GetItems(pageNumber);
                    }
                    else {
                        handleError(response.data.data);
                        resetForm();
                        GetItems(pageNumber);
                    }
                });
        }
    }
    const GetItems = (number) => {
        axios
            .get(APIConfig.APIACTIVATEURL + APIConfig.GETITEM + "?pageNumber=" + number + "&pageSize=" + pageSize + "", { ...headerconfig })
            .then((response) => {
                setItems(response.data.data.data);
                setPageNumber(response.data.data.pageNumber);
                setPageSize(response.data.data.pageSize);
                setTotalPages(response.data.data.totalPages);
                setData(response.data.data);
                setTotalRecords(response.data.data.totalRecords);
            });
    };
    const applyErrorClass = (field) =>
        field in errors && errors[field] === false ? " form-control-danger" : "";
    useEffect(() => {
        GetItems(pageNumber);
    }, [])
    return (
        <div id="layout-wrapper">
            <LayoutHeader></LayoutHeader>
            <LayoutSideBar></LayoutSideBar>
            <div className="main-content">
                <div className="page-content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                    <h4 className="mb-sm-0">Items</h4>
                                </div>
                            </div>
                        </div>
                        <div className="alert alert-success">
                            <form onSubmit={handleSubmit} autoComplete="off" noValidate>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="itemName" className="form-label">Item<span className="asterik">*</span></label>
                                            <input type="text" value={values.itemName} name="itemName" onChange={handleInputChange} className={"form-control" + applyErrorClass('itemName')} placeholder="Please enter item" />
                                            {errors.itemName === false ? (<div className="validationerror">Please enter item </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="cO2" className="form-label">CO2</label>
                                            <input type="number" value={values.cO2} name="cO2" onChange={handleInputChange} className={"form-control" + applyErrorClass('cO2')} placeholder="Please enter co2 value" />
                                            {errors.cO2 === false ? (<div className="validationerror">Please enter co2 </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="mb-3">
                                            <label htmlFor="oZone" className="form-label">OZone</label>
                                            <input type="number" value={values.oZone} name="oZone" onChange={handleInputChange} className={"form-control" + applyErrorClass('oZone')} placeholder="Please enter ozone value" />
                                            {errors.oZone === false ? (<div className="validationerror">Please enter oZone </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-9">
                                        <div className="mb-3">
                                            <label htmlFor="itemDescription" className="form-label">Description</label>
                                            <input type="text" value={values.itemDescription} name="itemDescription" onChange={handleInputChange} className={"form-control" + applyErrorClass('itemDescription')} placeholder="Please enter description" />
                                            {errors.itemDescription === false ? (<div className="validationerror">Please enter itemDescription </div>) : ('')}
                                        </div>
                                    </div>
                                    <div className="col-lg-2">
                                        <div className="hstack gap-2 justify-content-end mb-3 mt-4">
                                        {btnSubmit === true ? 
                                            (<button type="submit" className="btn btn-primary w-100">Submit</button>) : 
                                            (<button type="button" className="btn btn-primary w-100" disabled>Please wait...</button>)
                                        }
                                            <button type="button" className="btn btn-danger" onClick={resetForm}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="card card-height-100">
                                    <div className="card-body">
                                        <div className="table-responsive table-card">
                                            <table className="table align-middle" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th>Id</th>
                                                        <th>Item</th>
                                                        <th>CO2</th>
                                                        <th>Ozone</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {items.length > 0 && items.map((gt, index) =>
                                                        <tr key={gt.itemId}>
                                                            <td>{index + 1}</td>
                                                            <td className="id">{gt.itemName}</td>
                                                            <td className="id">{gt.cO2}</td>
                                                            <td className="id">{gt.oZone}</td>
                                                            <td>
                                                                <ul className="list-inline hstack gap-2 mb-0">
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Edit">
                                                                        <Link className="edit-item-btn" onClick={e => showEditDetails(gt)}><i className="ri-pencil-fill align-bottom text-muted" /></Link>
                                                                    </li>
                                                                    <li className="list-inline-item" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Delete">
                                                                        <Link className="remove-item-btn" onClick={e => onDelete(e, gt.itemId)}>
                                                                            <i className="ri-delete-bin-fill align-bottom text-muted" />
                                                                        </Link>
                                                                    </li>
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="align-items-center mt-4 pt-2 justify-content-between d-flex">
                                            <div className="flex-shrink-0">
                                                <div className="text-muted">
                                                    Showing <span className="fw-semibold">{items.length}</span> of <span className="fw-semibold">{totalRecords}</span> Results
                                                </div>
                                            </div>
                                            <ul className="pagination pagination-separated pagination-sm mb-0">
                                                <li className={"page-item" + data.previousPage === null ? 'disabled' : ''} onClick={() => GetFirstPageData()}>
                                                    <Link className="page-link">Previous</Link>
                                                </li>
                                                {renderPageNumbers}
                                                <li className={"page-item" + data.nextPage === null ? 'disabled' : ''} onClick={() => GetLastPageData()}>
                                                    <Link className="page-link">Next</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> {/* .card*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}